/// <reference types="p5/global" />
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const SketchContainer = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  max-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #141414;
  border-radius: 8px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: relative;
  pointer-events: none;

  @media (max-width: 768px) {
    aspect-ratio: 4/3;
    max-height: none;
    height: 300px;
  }

  canvas {
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    pointer-events: auto;
  }
`;

const MuseoTagSketch: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<any>(null);
  const isInitialized = useRef(false);

  useEffect(() => {
    // Prevent double initialization
    if (isInitialized.current || !containerRef.current) return;
    isInitialized.current = true;

    const loadLibraries = async () => {
      const [p5Module, matterModule] = await Promise.all([
        import('p5'),
        import('matter-js')
      ]);

      const p5 = p5Module.default;
      const Matter = matterModule.default;

      // Clean up any existing p5 instances
      if (canvasRef.current) {
        canvasRef.current.remove();
      }

      // Remove any existing canvases
      const existingCanvases = containerRef.current?.querySelectorAll('canvas');
      existingCanvases?.forEach(canvas => canvas.remove());

      const sketch = (p: any) => {
        // Module aliases for Matter.js
        let Engine = Matter.Engine,
            World = Matter.World,
            Bodies = Matter.Bodies;

        // Create a physics engine and world
        let engine: any;
        let world: any;

        // Arrays to store objects
        let nameTag: any;
        let letters: any[] = [];

        // Demo mode variables
        let isDemoMode = true;
        let demoState = 'typing'; // 'typing', 'pausing', 'deleting'
        let demoTimer = 0;
        let demoNames = ['Try Me', 'Nurullah', 'Museo Tag', 'Type Here', 'Hello World', 'MoMA', 'Typography'];
        let currentDemoNameIndex = 0;
        let demoText = '';
        let demoSubIndex = 0;
        let demoTypingSpeed = 150; // ms per character
        let demoPauseTime = 2000; // ms to pause after typing
        let demoDeleteSpeed = 100; // ms per character
        let lastDemoUpdateTime = 0;

        function calculateDimensions() {
          if (!containerRef.current) return { width: 800, height: 450 };
          const container = containerRef.current;
          const containerWidth = container.clientWidth;
          const containerHeight = container.clientHeight;
          
          // Ensure minimum dimensions even on small screens
          return {
            width: Math.max(300, containerWidth),
            height: Math.max(200, containerHeight)
          };
        }

        // Update canvas dimensions
        const dimensions = calculateDimensions();
        let canvasWidth = dimensions.width;
        let canvasHeight = dimensions.height;

        // Sample letters for random selection
        const alphabet = 'abcdefghijklmnopqrstuvwxyz'; // Only lowercase letters

        // Variable to store current typed name
        let currentName = "";
        let hiddenInput: any;

        // Update demo state
        function updateDemo() {
          if (!isDemoMode) return;
          
          const currentTime = p.millis();
          const deltaTime = currentTime - lastDemoUpdateTime;
          
          if (demoState === 'typing') {
            if (deltaTime >= demoTypingSpeed) {
              const targetName = demoNames[currentDemoNameIndex];
              if (demoSubIndex < targetName.length) {
                demoText = targetName.substring(0, demoSubIndex + 1);
                demoSubIndex++;
                
                // Update the nameTag and trigger letter creation
                const prevLength = currentName.length;
                currentName = demoText;
                nameTag.word = currentName;
                
                // Add letters if name got longer
                if (currentName.length > prevLength) {
                  const charsAdded = currentName.length - prevLength;
                  spawnRandomLetters(charsAdded * 3);
                }
                
                lastDemoUpdateTime = currentTime;
              } else {
                demoState = 'pausing';
                demoTimer = 0;
                lastDemoUpdateTime = currentTime;
              }
            }
          } else if (demoState === 'pausing') {
            demoTimer += deltaTime;
            if (demoTimer >= demoPauseTime) {
              demoState = 'deleting';
              lastDemoUpdateTime = currentTime;
            }
          } else if (demoState === 'deleting') {
            if (deltaTime >= demoDeleteSpeed) {
              if (demoSubIndex > 0) {
                demoSubIndex--;
                const targetName = demoNames[currentDemoNameIndex];
                demoText = targetName.substring(0, demoSubIndex);
                
                // Update the nameTag and trigger letter removal
                const prevLength = currentName.length;
                currentName = demoText.length > 0 ? demoText : "Hello";
                nameTag.word = currentName;
                
                // Remove letters if name got shorter
                if (prevLength > currentName.length) {
                  const charsDeleted = prevLength - currentName.length;
                  const lettersToRemove = Math.min(letters.length - 20, charsDeleted * 3);
                  
                  if (lettersToRemove > 0) {
                    for (let i = 0; i < lettersToRemove; i++) {
                      if (letters.length <= 20) break;
                      const randomIndex = Math.floor(p.random(letters.length));
                      World.remove(world, letters[randomIndex].body);
                      letters.splice(randomIndex, 1);
                    }
                  }
                }
                
                lastDemoUpdateTime = currentTime;
              } else {
                // Move to the next name
                currentDemoNameIndex = (currentDemoNameIndex + 1) % demoNames.length;
                demoState = 'typing';
                lastDemoUpdateTime = currentTime;
              }
            }
          }
        }

        // Stop demo mode and switch to user input
        function exitDemoMode() {
          if (!isDemoMode) return;
          
          isDemoMode = false;
          
          // Focus the hidden input
          if (hiddenInput && hiddenInput.elt) {
            hiddenInput.elt.focus({ preventScroll: true });
            hiddenInput.value('');
          }
          
          // Reset to empty/default
          currentName = '';
          nameTag.word = 'Hello';
        }

        class Name {
          body: Matter.Body;
          w: number;
          h: number;
          word: string;

          constructor(x: number, y: number, w: number, h: number, word: string) {
            const options = {
              friction: 0,
              restitution: 0.1,
              isStatic: true,
            };

            this.w = w;
            this.h = h;
            this.word = word;

            this.body = Bodies.rectangle(x, y, w, h, options);
            World.add(world, this.body);
          }

          show() {
            const pos = this.body.position;
            p.push();
            p.translate(pos.x, pos.y);
            p.rectMode(p.CENTER);
            p.noStroke();
            p.fill(255);
            // Dynamically adjust text size based on word length
            const baseSize = 75;
            const textSize = Math.max(30, baseSize * (15 / Math.max(15, this.word.length)));
            p.textSize(textSize);
            p.textAlign(p.CENTER, p.CENTER);
            // Increase width for longer words
            const textWidth = Math.max(400, this.word.length * 35);
            p.text(this.word, 0, 0);
            p.pop();
          }
        }

        class Letters {
          body: Matter.Body;
          w: number;
          h: number;
          letter: string;
          initialX: number;
          initialY: number;
          offsetX: number;
          offsetY: number;
          floatSpeed: number;
          rotationSpeed: number;
          phase: number;

          constructor(x: number, y: number, w: number, h: number, ranLetter: string) {
            const options = {
              friction: 0.3,
              restitution: 0.6,
              isStatic: true
            };

            this.body = Bodies.rectangle(x, y, w, h, options);
            this.w = w;
            this.h = h;
            this.letter = ranLetter;
            this.initialX = x;
            this.initialY = y;
            // Give each letter a unique floating pattern
            this.offsetX = p.random(5, 15);
            this.offsetY = p.random(5, 15);
            this.floatSpeed = p.random(0.001, 0.002);
            this.rotationSpeed = p.random(0.0005, 0.001);
            this.phase = p.random(0, p.TWO_PI);
            World.add(world, this.body);
          }

          update() {
            const time = p.frameCount * this.floatSpeed + this.phase;
            
            // More complex organic movement
            const targetX = this.initialX + 
              Math.sin(time) * this.offsetX + 
              Math.sin(time * 0.4) * (this.offsetX * 0.3) +
              Math.cos(time * 0.5) * (this.offsetX * 0.2);
            
            const targetY = this.initialY + 
              Math.cos(time * 1.2) * this.offsetY + 
              Math.cos(time * 0.6) * (this.offsetY * 0.3) +
              Math.sin(time * 0.7) * (this.offsetY * 0.2);
            
            const currentPos = this.body.position;
            const newX = p.lerp(currentPos.x, targetX, 0.02);
            const newY = p.lerp(currentPos.y, targetY, 0.02);
            
            Matter.Body.setPosition(this.body, {
              x: newX,
              y: newY
            });
            
            // More varied rotation
            const rotation = 
              Math.sin(p.frameCount * this.rotationSpeed + this.phase) * 0.3 +
              Math.sin(p.frameCount * this.rotationSpeed * 0.7) * 0.15;
            Matter.Body.setAngle(this.body, rotation);
          }

          show() {
            const pos = this.body.position;
            const angle = this.body.angle;
            
            p.push();
            p.translate(pos.x, pos.y);
            p.rotate(angle);
            p.noStroke();
            p.fill(255, 200); // Slightly gray (80% opacity)
            p.textSize(30); // Fixed size for all letters
            p.textStyle(p.BOLD);
            p.textAlign(p.CENTER, p.CENTER);
            p.text(this.letter, 0, 0);
            p.pop();
          }
        }

        function spawnRandomLetters(count: number) {
          const isMobile = window.innerWidth <= 768;
          const margin = isMobile ? 40 : 60; // Smaller margin on mobile
          const centerX = p.width / 2;
          const centerY = p.height / 2;
          const textWidth = isMobile ? 400 : 600; // Smaller text box on mobile
          const textHeight = isMobile ? 100 : 150;
          const letterSize = isMobile ? 24 : 30; // Smaller letters on mobile
          const minSpacing = isMobile ? 32 : 42; // Closer spacing on mobile
          
          // Reduce letter count on mobile for better performance
          const adjustedCount = isMobile ? Math.ceil(count * 0.7) : count;
          
          // Helper function to check if a position is valid
          const isValidPosition = (x: number, y: number): boolean => {
            // Only avoid the rectangular text area
            const insideTextBox = Math.abs(x - centerX) < textWidth/2 && 
                                Math.abs(y - centerY) < textHeight/2;

            // Check distance from other letters
            const tooCloseToOtherLetter = letters.some(letter => {
              const dx = letter.initialX - x;
              const dy = letter.initialY - y;
              return Math.sqrt(dx * dx + dy * dy) < minSpacing;
            });

            // Only ensure we're not inside the text box
            return !insideTextBox && !tooCloseToOtherLetter;
          };

          // Try to add new letters
          let successfulAdds = 0;
          let totalAttempts = 0;
          const maxTotalAttempts = 300;

          while (successfulAdds < adjustedCount && totalAttempts < maxTotalAttempts) {
            let x, y;
            let attempts = 0;
            const maxAttempts = 50;

            do {
              // Fully random position across the canvas
              x = p.random(margin, p.width - margin);
              y = p.random(margin, p.height - margin);
              
              attempts++;
              totalAttempts++;
              
              if (attempts >= maxAttempts) break;
            } while (!isValidPosition(x, y));

            if (isValidPosition(x, y)) {
              const letter = new Letters(
                x, y,
                letterSize, letterSize,
                alphabet.charAt(p.floor(p.random(alphabet.length)))
              );
              letters.push(letter);
              successfulAdds++;
            }
          }

          // Update movement parameters - less movement on mobile for better performance
          letters.forEach(letter => {
            letter.offsetX = p.random(isMobile ? 5 : 8, isMobile ? 8 : 12);
            letter.offsetY = p.random(isMobile ? 5 : 8, isMobile ? 8 : 12);
            letter.floatSpeed = p.random(0.0002, 0.0004);
            letter.rotationSpeed = p.random(0.0001, 0.0002);
            letter.phase = p.random(0, p.TWO_PI);
          });
        }

        function handleTyping() {
          if (isDemoMode) {
            exitDemoMode();
            return;
          }
          
          const newInput = (hiddenInput.value() as string);
          
          if (typeof newInput === 'string' && typeof currentName === 'string') {
            if (newInput.length < currentName.length) {
              // When deleting, remove some letters
              const charsDeleted = currentName.length - newInput.length;
              const lettersToRemove = Math.min(letters.length - 20, charsDeleted * 3); // Keep at least 20 letters
              
              if (lettersToRemove > 0) {
                // Remove random letters
                for (let i = 0; i < lettersToRemove; i++) {
                  if (letters.length <= 20) break; // Ensure we keep some minimum
                  const randomIndex = Math.floor(p.random(letters.length));
                  World.remove(world, letters[randomIndex].body);
                  letters.splice(randomIndex, 1);
                }
              }
              
              currentName = newInput;
              nameTag.word = currentName.length > 0 ? currentName : "Hello";
            } else if (newInput.length > currentName.length) {
              // When typing, add more letters
              const charsAdded = newInput.length - currentName.length;
              spawnRandomLetters(charsAdded * 3);
              currentName = newInput;
              nameTag.word = currentName;
            }
          }
        }

        // Add window resize handler
        p.windowResized = () => {
          const newDimensions = calculateDimensions();
          canvasWidth = newDimensions.width;
          canvasHeight = newDimensions.height;
          p.resizeCanvas(canvasWidth, canvasHeight);
          
          // Update nameTag position
          if (nameTag) {
            Matter.Body.setPosition(nameTag.body, {
              x: p.width/2,
              y: p.height/2
            });
          }
        };

        p.setup = () => {
          const canvas = p.createCanvas(canvasWidth, canvasHeight, p.P2D);
          
          // Enable hardware acceleration for better mobile performance
          if (canvas.elt) {
            canvas.elt.style.transform = 'translateZ(0)';
            canvas.elt.style.backfaceVisibility = 'hidden';
          }
          
          // Set canvas parent element styles
          const canvasParent = canvas.parent();
          if (canvasParent) {
            canvasParent.style.width = '100%';
            canvasParent.style.height = '100%';
            canvasParent.style.display = 'flex';
            canvasParent.style.justifyContent = 'center';
            canvasParent.style.alignItems = 'center';
          }
          
          // Disable default touch handling on the canvas
          if (canvas.elt) {
            canvas.elt.style.touchAction = 'none';
            
            // Use native event listener instead of p5's event system
            canvas.elt.addEventListener('mousedown', (e: MouseEvent) => {
              if (!canvas.elt) return;
              
              try {
                const rect = canvas.elt.getBoundingClientRect();
                const mouseX = e.clientX - rect.left;
                const mouseY = e.clientY - rect.top;
                
                if (mouseX >= 0 && mouseX <= p.width && 
                    mouseY >= 0 && mouseY <= p.height) {
                  e.stopPropagation();
                  e.preventDefault();
                  
                  // Exit demo mode on interaction
                  exitDemoMode();
                  
                  hiddenInput.elt?.focus({ preventScroll: true });
                  spawnRandomLetters(5);
                }
              } catch (error) {
                console.error('Error handling mouse event:', error);
              }
            });
            
            canvas.elt.addEventListener('touchstart', (e: TouchEvent) => {
              if (!canvas.elt) return;
              
              try {
                const rect = canvas.elt.getBoundingClientRect();
                const touch = e.touches[0];
                const touchX = touch.clientX - rect.left;
                const touchY = touch.clientY - rect.top;
                
                if (touchX >= 0 && touchX <= p.width && 
                    touchY >= 0 && touchY <= p.height) {
                  e.stopPropagation();
                  
                  // Exit demo mode on interaction
                  exitDemoMode();
                  
                  hiddenInput.elt?.focus({ preventScroll: true });
                  spawnRandomLetters(5);
                }
              } catch (error) {
                console.error('Error handling touch event:', error);
              }
            }, { passive: false });
          }
          
          hiddenInput = p.createInput('') as any;
          hiddenInput.position(0, -50);
          hiddenInput.attribute('autocomplete', 'off');
          hiddenInput.attribute('autocorrect', 'off');
          hiddenInput.attribute('autocapitalize', 'off');
          hiddenInput.attribute('spellcheck', 'false');
          hiddenInput.style('opacity', '0');
          hiddenInput.style('position', 'fixed'); // Change to fixed positioning
          
          // Initial focus only on setup
          if (hiddenInput.elt) {
            hiddenInput.elt.focus({ preventScroll: true });
          }
          
          // @ts-ignore
          hiddenInput.input(handleTyping);
          
          engine = Engine.create();
          world = engine.world;
          
          // Remove gravity since we're controlling movement manually
          engine.world.gravity.y = 0;
          
          nameTag = new Name(p.width/2, p.height/2, 400, 100, currentName);
          
          p.textAlign(p.CENTER, p.CENTER);
          
          // Start with demo text
          currentName = demoNames[0].substring(0, 1);
          demoText = currentName;
          demoSubIndex = 1;
          lastDemoUpdateTime = p.millis();
          
          // Start with fewer letters on mobile
          const isMobile = window.innerWidth <= 768;
          const initialLetterCount = isMobile ? 15 : 20;
          spawnRandomLetters(initialLetterCount);
        };

        p.draw = () => {
          const isMobile = window.innerWidth <= 768;
          if (isMobile && p.frameCount % 2 !== 0) return; // Skip every other frame on mobile
          
          p.background(20);
          
          Engine.update(engine, isMobile ? 32 : 16); // Slower physics on mobile
          
          // Update demo if active
          if (isDemoMode) {
            updateDemo();
          }
          
          // Draw the main text
          nameTag.show();
          
          // Draw letters
          for (let letter of letters) {
            letter.update();
            letter.show();
          }
          
          p.fill(255, 180);
          p.textSize(isMobile ? 14 : 16);
          
          // Change bottom text based on mode
          if (isDemoMode) {
            p.text("Tap to try it yourself", p.width/2, p.height - 30);
          } else {
            p.text("Type to change the text", p.width/2, p.height - 30);
          }
        };

        // Remove p5's event handlers
        p.mousePressed = () => {};
        p.touchStarted = () => {};
        p.mouseWheel = () => true;
      };

      if (containerRef.current) {
        canvasRef.current = new p5(sketch, containerRef.current);
      }
    };

    loadLibraries().catch(console.error);

    return () => {
      if (canvasRef.current) {
        canvasRef.current.remove();
        isInitialized.current = false;
      }
    };
  }, []);

  return <SketchContainer ref={containerRef} />;
};

export default MuseoTagSketch; 