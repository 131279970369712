import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import ContactBar from './ContactBar';
import ThemeToggle from '../theme/ThemeToggle';
import { LAYOUT, BREAKPOINTS } from '../../styles/layout';

const IntroSectionContainer = styled.header`
  width: 100%;
  font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  font-weight: 590;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 0;
  padding: 144px 0 0;
  
  @media (max-width: ${BREAKPOINTS.tablet}) {
    padding: 100px 0 0;
    min-height: calc(100vh - 60px);
  }
  
  @media (max-width: ${BREAKPOINTS.mobile}) {
    padding: 80px 0 0;
  }
`;

const IntroContent = styled.div`
  width: 100%;
  max-width: ${LAYOUT.maxWidth};
  margin: 0 auto;
  padding: ${LAYOUT.containerPadding};
  opacity: 0;
  animation: fadeIn 0.8s ease-out forwards;
  position: relative;
  
  @media (max-width: ${BREAKPOINTS.tablet}) {
    padding: ${LAYOUT.containerPadding};
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const IntroText = styled.p`
  color: var(--text);
  font-size: 63px;
  font-weight: 700;
  margin: 0;
  line-height: 1.2;
  letter-spacing: -0.02em;
  max-width: 95%;
  
  @media (max-width: 991px) {
    font-size: 36px;
    line-height: 1.3;
    max-width: 100%;
  }
  
  @media (max-width: 480px) {
    font-size: 28px;
    line-height: 1.4;
  }
`;

const ReadMoreLink = styled.a`
  color: var(--secondary-text);
  font-size: 21px;
  font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  font-weight: 590;
  margin-top: 30px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease;
  gap: 8px;
  
  svg {
    width: 24px;
    height: 24px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: center;
    animation: arrowPulse 2s infinite;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    
    path {
      stroke: currentColor;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: none;
    }
  }
  
  &:hover {
    color: var(--text);
    transform: translateX(4px);
    
    svg {
      transform: translateX(4px) scale(1.2);
      animation: none;
    }
  }
  
  @keyframes arrowPulse {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(2px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @media (max-width: 991px) {
    margin-top: 25px;
    font-size: 18px;
    
    svg {
      width: 20px;
      height: 20px;
      margin-top: -1px;
    }
  }
  
  @media (max-width: 480px) {
    margin-top: 20px;
    font-size: 16px;
    
    svg {
      width: 18px;
      height: 18px;
      margin-top: -1px;
    }
  }
`;

const ThemeToggleWrapper = styled.div`
  position: absolute;
  right: ${LAYOUT.contentPadding};
  top: 0;
  
  @media (max-width: ${BREAKPOINTS.tablet}) {
    top: 20px;
  }
`;

const IntroFooter = styled.div<{ initialLoad: boolean }>`
  width: 100%;
  max-width: ${LAYOUT.maxWidth};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 46px ${LAYOUT.contentPadding};
  background: var(--background);
  position: ${props => props.initialLoad ? 'absolute' : 'relative'};
  bottom: ${props => props.initialLoad ? '0' : 'auto'};
  left: ${props => props.initialLoad ? '50%' : 'auto'};
  transform: ${props => props.initialLoad ? 'translateX(-50%)' : 'none'};
  transition: opacity 0.3s ease;
  opacity: ${props => props.initialLoad ? '1' : '0.8'};
  
  &:hover {
    opacity: 1;
  }
  
  @media (max-width: ${BREAKPOINTS.tablet}) {
    padding: 30px ${LAYOUT.contentPadding};
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
`;

const FooterRight = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ScrollText = styled.p`
  color: var(--secondary-text);
  font-size: 21px;
  margin: 0;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
  
  &:hover {
    color: var(--text);
  }
  
  @media (max-width: 991px) {
    font-size: 16px;
  }
`;

const ScrollTextDark = styled.span`
  color: var(--text);
  margin-left: 4px;
`;

const UpdateText = styled.p`
  color: var(--text);
  font-size: 21px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  
  @media (max-width: 991px) {
    font-size: 16px;
  }
`;

const StatusDot = styled.span<{ visible: boolean }>`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #4CAF50;
  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: scale(${props => props.visible ? 1 : 0.8});
`;

const LastUpdatedTime = styled.span`
  font-weight: 500;
  font-size: 21px;
  color: var(--text);
  
  @media (max-width: 991px) {
    font-size: 16px;
  }
`;

const IntroSection: React.FC = () => {
  const [currentTime, setCurrentTime] = useState<string>('');
  const [dotVisible, setDotVisible] = useState<boolean>(true);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (initialLoad && window.scrollY > 0) {
        setInitialLoad(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [initialLoad]);

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const hawaiiDate = new Date(now.toLocaleString('en-US', { timeZone: 'Pacific/Honolulu' }));
      let hours = hawaiiDate.getHours();
      const isPM = hours >= 12;
      hours = hours % 12;
      hours = hours === 0 ? 12 : hours;
      const minutes = hawaiiDate.getMinutes().toString().padStart(2, '0');
      const timeString = `${hours}:${minutes} ${isPM ? 'PM' : 'AM'}`;
      setCurrentTime(timeString);
    };
    
    const toggleDot = () => {
      setDotVisible(prev => !prev);
    };
    
    updateTime();
    const timeInterval = setInterval(updateTime, 1000);
    const dotInterval = setInterval(toggleDot, 800);
    
    return () => {
      clearInterval(timeInterval);
      clearInterval(dotInterval);
    };
  }, []);
  
  return (
    <IntroSectionContainer className="intro-section">
      <MainContent>
        <IntroContent className="intro-content">
          <IntroText className="intro-text">
            Hey! I'm Nur, a designer based in Hawaii, helping small businesses thrive at GoDaddy. Love turning tricky problems into simple solutions, when not catching waves 🏄‍♂️
          </IntroText>
          <ReadMoreLink href="/story" className="read-more-link">
            Read More
            <svg viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"/>
            </svg>
          </ReadMoreLink>
        </IntroContent>
        
        <ContactBar />
      </MainContent>
      
      <IntroFooter className="intro-footer" initialLoad={initialLoad}>
        <ScrollText className="scroll-text">
          Scroll down
          <ScrollTextDark className="scroll-text-dark"> for selected works</ScrollTextDark>
        </ScrollText>
        <FooterRight>
          <UpdateText className="update-text">
            Hawaii <StatusDot id="statusDot" visible={dotVisible} className={dotVisible ? 'dot-visible' : ''} /> <LastUpdatedTime id="lastUpdatedTime">{currentTime}</LastUpdatedTime>
          </UpdateText>
          <ThemeToggle />
        </FooterRight>
      </IntroFooter>
    </IntroSectionContainer>
  );
};

export default IntroSection; 