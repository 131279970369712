import React from 'react';
import styled from 'styled-components';
import AnimatedTextarea from '../ui/AnimatedTextarea';
import AnimatedTypeSample from './AnimatedTypeSample';
import { LAYOUT, BREAKPOINTS } from '../../styles/layout';
import { Link } from 'react-router-dom';

interface TypeDesignSectionProps {
  title: string;
  description: string;
  role: string;
  timeframe: string;
  logoSrc: string;
  logoAlt: string;
  typeSampleSrc: string;
  typeSampleAlt: string;
  readMoreLink?: string;
  fontName?: string;
  fontFamily?: string;
  isDarkBackground?: boolean;
  typeSampleMaxWidth?: string;
  processLink?: string;
}

interface EditableTextProps {
  fontSize: number;
  fontFamily?: string;
}

interface DesignNameProps {
  fontFamily?: string;
}

const Section = styled.section`
  display: flex;
  margin: 144px auto 100px;
  width: 100%;
  max-width: ${LAYOUT.maxWidth};
  flex-direction: column;
  align-items: stretch;
  padding: ${LAYOUT.containerPadding};
  
  @media (max-width: ${BREAKPOINTS.tablet}) {
    margin: 70px auto 50px;
    padding: ${LAYOUT.containerPadding};
  }
`;

const Header = styled.div`
  display: block;
  width: 100%;
  max-width: ${LAYOUT.maxWidth};
  margin: 0;
  color: var(--text);
  font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  font-size: 53px;
  font-weight: 590;
  line-height: 1.2;
  
  @media (max-width: ${BREAKPOINTS.tablet}) {
    width: 100%;
    font-size: 28px;
    margin-bottom: 30px;
  }
`;

const TitleContainer = styled.div`
  border-radius: 0px 0px 0px 0px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  font-size: 21px;
  color: var(--text);
  font-weight: 590;
  margin-bottom: 40px;
  
  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
`;

const SectionTitle = styled.h4`
  font-size: 1.1rem;
  color: var(--secondary-text);
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

const Divider = styled.hr`
  margin-top: 20px;
  background-color: var(--divider);
  height: 1px;
  width: 100%;
  z-index: 10;
  flex-shrink: 0;
  border: none;
  
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Intro = styled.div`
  margin: 40px 0;
  width: 100%;
  max-width: 100%;
  
  @media (max-width: 991px) {
    margin: 20px 0;
  }
`;

const DesignColumns = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
  
  @media (max-width: 991px) {
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
`;

const LogoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  line-height: normal;
  width: fit-content;
  margin-left: 0px;
  
  @media (max-width: 991px) {
    width: auto;
  }
`;

const DesignLogo = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 108px;
  flex-shrink: 0;
  max-width: 100%;
  
  @media (max-width: 991px) {
    width: 60px;
    margin-top: 0;
  }
`;

const NameColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  line-height: normal;
  width: 78%;
  margin-left: 20px;
  
  @media (max-width: 991px) {
    width: auto;
    margin-left: 0;
  }
`;

const DesignName = styled.h3<DesignNameProps>`
  font-size: 2.8rem;
  font-family: ${props => props.fontFamily || '"Panoptikon", -apple-system, Roboto, Helvetica, sans-serif'};
  font-weight: 600;
  margin: 0 0 1.5rem 0;
  color: var(--text);
  max-width: 92%;
  
  @media (max-width: 991px) {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
`;

const DesignDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--text);
  margin-bottom: 1.5rem;
  max-width: 90%;

  @media (max-width: 991px) {
    font-size: 28px;
  }
`;

const DescriptionContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  margin-bottom: 40px;
  
  @media (max-width: 991px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const ProjectDescription = styled.p`
  font-size: 32px;
  line-height: 1.6;
  color: var(--text);
  font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  font-weight: 274;
  margin: 0 0 2rem 0;
  max-width: 90%;

  @media (max-width: 991px) {
    font-size: 28px;
  }
`;

const ReadMoreLink = styled.a`
  text-decoration: none;
  color: var(--secondary-text);
  display: inline-block;
  margin: 0 0 2rem 0;
  font-weight: 500;
  font-size: 1.1rem;
  position: relative;
  transition: color 0.3s ease;
  width: auto;
  
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: var(--text);
    transition: width 0.3s ease;
  }
  
  &:hover {
    color: var(--text);
    
    &:after {
      width: 100%;
    }
  }
`;

const ProjectMeta = styled.div`
  margin-top: 50px;
  width: 100%;
  max-width: 1160px;
  font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  font-size: 21px;
  color: var(--text);
  margin-bottom: 40px;
  
  @media (max-width: 991px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const MetaInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
  
  @media (max-width: 991px) {
    max-width: 100%;
    padding-bottom: 15px;
  }
`;

const Role = styled.p`
  font-weight: 590;
  align-self: stretch;
  margin: 0;
  font-size: 21px;
`;

const Timeframe = styled.p`
  font-weight: 400;
  text-align: right;
  align-self: stretch;
  margin: 0;
  font-size: 21px;
`;

const Showcase = styled.div`
  border-radius: 0px 0px 0px 0px;
  margin-top: 30px;
  width: 100%;
  
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 10px;
  }
`;

const DarkContainer = styled.div<{ isDark?: boolean }>`
  width: 100%;
  background-color: ${props => props.isDark ? 'var(--background-darker)' : 'var(--background)'};
  border: ${props => props.isDark ? 'none' : '1px solid var(--divider)'};
  border-radius: ${props => props.isDark ? '48px' : '8px'};
  padding: ${props => props.isDark ? '40px 40px' : '30px'};
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: ${BREAKPOINTS.tablet}) {
    padding: ${props => props.isDark ? '20px 15px' : '15px'};
    margin: 10px 0;
    border-radius: ${props => props.isDark ? '24px' : '8px'};
  }
`;

const TypeSample = styled.img<{ maxWidth?: string }>`
  width: 100%;
  height: auto;
  object-fit: contain;
  max-width: ${props => props.maxWidth || '100%'};
  display: block;
  margin: 0 auto;
  
  @media (max-width: ${BREAKPOINTS.tablet}) {
    max-width: 100%;
  }
`;

const Demo = styled.div`
  align-self: center;
  display: flex;
  margin-top: 80px;
  width: 1160px;
  max-width: 100%;
  padding-right: 0;
  padding-bottom: 50px;
  flex-direction: column;
  align-items: start;
  
  @media (max-width: 991px) {
    padding-right: 0;
    margin-top: 30px;
    padding-bottom: 30px;
  }
`;

const Controls = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 35px;
  flex-wrap: wrap;
`;

const FontSizeControl = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: "Poppins", -apple-system, Roboto, Helvetica, sans-serif;
  font-size: 14px;
  color: var(--text);
  width: 100%;
`;

const FontSizeLabel = styled.span`
  font-weight: 700;
  min-width: 85px;
`;

const FontSizeValue = styled.span`
  min-width: 45px;
`;

const FontSizeSlider = styled.input`
  flex: 1;
  -webkit-appearance: none;
  height: 4px;
  border-radius: 2px;
  background: var(--divider);
  outline: none;
  padding: 0;
  margin: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: rgba(238, 42, 40, 1);
    cursor: pointer;
    transition: background .15s ease-in-out;

    &:hover {
      background: rgba(200, 35, 33, 1);
    }
  }

  &::-moz-range-thumb {
    width: 18px;
    height: 18px;
    border: 0;
    border-radius: 50%;
    background: rgba(238, 42, 40, 1);
    cursor: pointer;
    transition: background .15s ease-in-out;

    &:hover {
      background: rgba(200, 35, 33, 1);
    }
  }
`;

const EditableText = styled.textarea<EditableTextProps>`
  width: 100%;
  min-height: 150px;
  margin-top: 30px;
  padding: 20px;
  border: 1px solid var(--divider);
  border-radius: 8px;
  background: transparent;
  color: var(--text);
  font-size: ${props => props.fontSize}px;
  font-family: ${props => props.fontFamily || '"Panoptikon", -apple-system, Roboto, Helvetica, sans-serif'};
  font-weight: 400;
  line-height: 1.3;
  resize: vertical;
  
  &:focus {
    outline: none;
    border-color: var(--secondary-text);
  }
  
  @media (max-width: 991px) {
    font-size: ${props => Math.min(props.fontSize, 28)}px;
  }
`;

// Add some custom styling to override the AnimatedTextarea for this specific usage
const StyledAnimatedTextarea = styled(AnimatedTextarea)`
  // No need for additional styles, they're already in the component with !important
`;

const ProcessButton = styled(Link)`
  text-decoration: none;
  color: var(--secondary-text);
  display: inline-block;
  margin: 20px 0 2rem 0;
  font-weight: 500;
  font-size: 1.1rem;
  position: relative;
  transition: color 0.3s ease;
  width: auto;
  
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: var(--text);
    transition: width 0.3s ease;
  }
  
  &:hover {
    color: var(--text);
    
    &:after {
      width: 100%;
    }
  }
`;

const ProjectTitle = styled.h2`
  font-size: 2.625rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  color: var(--text);
  max-width: 90%;

  @media (max-width: 991px) {
    font-size: 28px;
  }
`;

const TypeDesignSection: React.FC<TypeDesignSectionProps> = ({
  title,
  description,
  role,
  timeframe,
  logoSrc,
  logoAlt,
  typeSampleSrc,
  typeSampleAlt,
  readMoreLink,
  fontName = "Panoptikon",
  fontFamily = '"Panoptikon", -apple-system, Roboto, Helvetica, sans-serif',
  isDarkBackground = false,
  typeSampleMaxWidth,
  processLink
}) => {
  const [fontSize, setFontSize] = React.useState(63);

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = parseInt(e.target.value);
    if (!isNaN(newSize) && newSize > 0) {
      setFontSize(newSize);
      // Force re-render by updating a state value
      setFontSize(newSize);
    }
  };

  // Check if this is the Plato's Hat section
  const isPlatosHat = fontName === "Plato's Hat";

  return (
    <Section className="type-design-section">
      <Header className="type-design-header">
        <TitleContainer className="type-design-title-container">
          <SectionTitle className="section-title">{title}</SectionTitle>
          <Divider className="divider" />
        </TitleContainer>
        <Intro className="type-design-intro">
          <DesignColumns className="type-design-columns">
            <LogoColumn className="type-design-logo-column">
              <DesignLogo className="type-design-logo" src={logoSrc} alt={logoAlt} />
            </LogoColumn>
            <NameColumn className="type-design-name-column">
              <DesignName className="type-design-name" fontFamily={fontFamily}>{fontName}</DesignName>
            </NameColumn>
          </DesignColumns>
        </Intro>
        <DescriptionContainer className="type-design-description-container">
          <ProjectDescription className="project-description">{description}</ProjectDescription>
          {processLink && (
            <ProcessButton to={processLink}>See the process</ProcessButton>
          )}
          {readMoreLink && (
            <ReadMoreLink href={readMoreLink} className="read-more-link">Read More →</ReadMoreLink>
          )}
        </DescriptionContainer>
        <ProjectMeta className="project-meta">
          <MetaInfo className="meta-info">
            <Role className="role">{role}</Role>
            <Timeframe className="timeframe">{timeframe}</Timeframe>
          </MetaInfo>
          <Divider className="divider" />
        </ProjectMeta>
      </Header>
      
      <Showcase className="type-design-showcase">
        <DarkContainer className="type-design-dark-container" isDark={isDarkBackground}>
          {isPlatosHat ? (
            <AnimatedTypeSample 
              className="type-design-sample" 
              maxWidth={typeSampleMaxWidth}
            />
          ) : (
            <TypeSample 
              className="type-design-sample" 
              src={typeSampleSrc} 
              alt={typeSampleAlt}
              maxWidth={typeSampleMaxWidth}
            />
          )}
        </DarkContainer>
      </Showcase>
      
      <Demo className="type-design-demo">
        <Controls className="type-design-controls">
          <FontSizeControl>
            <FontSizeLabel>Font Size:</FontSizeLabel>
            <FontSizeSlider
              type="range"
              value={fontSize}
              onChange={handleFontSizeChange}
              min="12"
              max="200"
            />
            <FontSizeValue>{fontSize}px</FontSizeValue>
          </FontSizeControl>
        </Controls>
        
        <StyledAnimatedTextarea 
          fontSize={fontSize}
          fontFamily={fontFamily}
          placeholder={`Type something here to try ${fontName}...`}
          spellCheck={false}
          className="editable-text"
        />
      </Demo>
    </Section>
  );
};

export default TypeDesignSection; 