import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const FontLoaderWrapper = styled.div<{ $fontsLoaded: boolean }>`
  opacity: ${props => props.$fontsLoaded ? 1 : 0.97};
  transition: opacity 0.3s ease;
`;

/**
 * FontLoader ensures fonts are loaded before displaying content at full opacity
 * This helps prevent layout shifts and font flashing
 */
const FontLoader: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  
  useEffect(() => {
    // Check if the browser supports the fonts API
    if ('fonts' in document) {
      document.fonts.ready.then(() => {
        // Set fonts loaded to true once document.fonts are ready
        setFontsLoaded(true);
      });
    } else {
      // For browsers without font loading API, set a timeout
      const timer = setTimeout(() => {
        setFontsLoaded(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, []);
  
  return (
    <FontLoaderWrapper $fontsLoaded={fontsLoaded}>
      {children}
    </FontLoaderWrapper>
  );
};

export default FontLoader; 