import React from 'react';
import styled from 'styled-components';

const ContactBarContainer = styled.div`
  width: 100%;
  max-width: 1160px;
  margin: 46px auto 0;
  padding: 0 20px;
  
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Divider = styled.hr`
  background-color: var(--divider);
  height: 1px;
  width: 100%;
  border: none;
  margin: 0;
`;

const ContactInfo = styled.div`
  display: flex;
  padding: 20px 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  
  @media (max-width: 991px) {
    padding: 15px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
`;

const ContactHeading = styled.div`
  color: var(--text);
  font-size: 21px;
  margin: 0;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  font-weight: 590;
  padding: 4px 0;
  
  &:hover {
    opacity: 0.8;
  }
  
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: currentColor;
    transition: width 0.3s ease;
  }
  
  &:hover:after {
    width: 100%;
  }
  
  @media (max-width: 991px) {
    font-size: 18px;
    width: 100%;
  }
`;

const ContactLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  
  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

const ContactLink = styled.a`
  color: var(--secondary-text);
  text-decoration: none;
  font-size: 21px;
  font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  font-weight: 590;
  position: relative;
  transition: all 0.3s ease;
  padding: 4px 0;
  display: inline-block;
  
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--text);
    transition: width 0.3s ease;
  }
  
  &:hover {
    color: var(--text);
    
    &:after {
      width: 100%;
    }
  }
  
  @media (max-width: 991px) {
    font-size: 18px;
    padding: 4px 0;
    width: 100%;
  }
  
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const ContactBar: React.FC = () => {
  // Function to open contact modal
  const openContactModal = () => {
    // Use the same event-based approach as Footer.tsx
    const event = new CustomEvent('openContactModal');
    document.dispatchEvent(event);
  };

  return (
    <ContactBarContainer className="contact-bar">
      <Divider className="divider" />
      <ContactInfo className="contact-info">
        <ContactHeading className="contact-heading" onClick={openContactModal}>
          Contact
        </ContactHeading>
        <ContactLinks className="contact-links">
          <ContactLink href="mailto:nurullah.cemil@gmail.com" className="contact-link">
            Email
          </ContactLink>
          <ContactLink 
            href="https://www.linkedin.com/in/typeandcoffee/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="contact-link"
          >
            LinkedIn
          </ContactLink>
          <ContactLink 
            href="Nurullah-Gokdogan-Resume.pdf" 
            download 
            className="contact-link"
          >
            Resume
          </ContactLink>
        </ContactLinks>
      </ContactInfo>
      <Divider className="divider" />
    </ContactBarContainer>
  );
};

export default ContactBar; 