import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.footer`
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  padding: 20px;
`;

const FooterContact = styled.div`
  width: 100%;
`;

const Divider = styled.hr`
  background-color: var(--divider);
  height: 1px;
  width: 100%;
  z-index: 10;
  flex-shrink: 0;
  margin: 0;
  border: none;
`;

const ContactInfo = styled.div`
  display: flex;
  padding: 20px 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
    padding: 15px 0;
  }
`;

const ContactHeading = styled.div`
  color: var(--text);
  font-size: 25px;
  margin: 0;
  cursor: pointer;
  position: relative;
  transition: color 0.2s ease;
  font-weight: 590;
  
  &:hover {
    opacity: 0.8;
  }
`;

const ContactLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  font-size: 25px;
  color: var(--secondary-text);
  
  @media (max-width: 991px) {
    white-space: initial;
    gap: 20px;
  }
`;

const ContactLink = styled.a`
  color: var(--secondary-text);
  text-decoration: none;
  transition: color 0.2s ease;
  font-size: 25px;
  
  &:hover {
    color: var(--text);
  }
`;

const NavLink = styled(Link)`
  color: var(--secondary-text);
  text-decoration: none;
  transition: color 0.2s ease;
  font-size: 25px;
  
  &:hover {
    color: var(--text);
  }
`;

const FooterImage = styled.img`
  width: 100%;
  height: auto;
  margin: 40px 0;

  [data-theme="dark"] & {
    filter: invert(1);
  }
`;

const Copyright = styled.p`
  text-align: center;
  color: var(--secondary-text);
  font-size: 14px;
  margin: 20px 0;
`;

const CopyrightAccent = styled.span`
  color: var(--secondary-text);
`;

interface FooterSectionProps {
  onContactClick?: () => void;
}

const FooterSection: React.FC<FooterSectionProps> = ({ onContactClick }) => {
  return (
    <FooterContainer className="sc-fLwclE vqdlH footer-section">
      <FooterContact className="sc-bGvCjh IgLJL footer-contact">
        <Divider className="sc-ilSuNr jxyuyg divider" />
        <ContactInfo className="sc-jlUQNq csylRh contact-info">
          <ContactHeading className="sc-iwmfpJ jvqqYv contact-heading" onClick={onContactClick}>Contact</ContactHeading>
          <ContactLinks className="sc-jwoBpI HrNlH contact-links">
            <NavLink to="/story" className="sc-irCjQH leBTpc contact-link">Story</NavLink>
            <ContactLink href="mailto:nurullah.cemil@gmail.com" className="sc-irCjQH leBTpc contact-link">Email</ContactLink>
            <ContactLink href="https://www.linkedin.com/in/typeandcoffee/" target="_blank" className="sc-irCjQH leBTpc contact-link">LinkedIn</ContactLink>
            <ContactLink href="Nurullah-Gokdogan-Resume.pdf" download className="sc-irCjQH leBTpc contact-link">Resume</ContactLink>
          </ContactLinks>
        </ContactInfo>
        <Divider className="sc-ilSuNr jxyuyg divider" />
      </FooterContact>
      <FooterImage 
        className="sc-jrEFQG kDbxQR footer-image" 
        alt="Footer image" 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d86e4b89476061cc98d06e9295351b46892493511aece034d4beb54817b09297?placeholderIfAbsent=true&apiKey=45791709913447029a0f9f50a0a9387d" 
      />
      <Copyright className="sc-iBVUsZ cDfpIZ copyright">
        © 2025 Nurullah Gokdogan <CopyrightAccent className="sc-jBYqsY FFCuF copyright-accent">| Self Coded 🚀 - Updated</CopyrightAccent>
      </Copyright>
    </FooterContainer>
  );
};

export default FooterSection; 