import React from 'react';
import styled from 'styled-components';
import FooterSection from './FooterSection';
import Header from './Header';

interface LayoutProps {
  children: React.ReactNode;
  onContactClick?: () => void;
  showHeader?: boolean;
  backLink?: string;
  backText?: string;
}

const LayoutContainer = styled.div`
  font-family: 'SF Pro', -apple-system, Roboto, Helvetica, sans-serif;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const MainContent = styled.main`
  width: 100%;
  flex: 1;
`;

const Layout: React.FC<LayoutProps> = ({ 
  children, 
  onContactClick, 
  showHeader = true,
  backLink,
  backText 
}) => {
  return (
    <LayoutContainer>
      {showHeader && <Header backLink={backLink} backText={backText} />}
      <MainContent>{children}</MainContent>
      <FooterSection onContactClick={onContactClick} />
    </LayoutContainer>
  );
};

export default Layout;