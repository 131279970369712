import React, { useState, useEffect } from 'react';
import { ThemeContext, ThemeMode, lightTheme, darkTheme } from '../../styles/theme';

interface ThemeProviderProps {
  children: React.ReactNode;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  // Get initial theme from localStorage or determine based on Hawaii time
  const [mode, setMode] = useState<ThemeMode>(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme as ThemeMode;
    }
    
    // For first-time users, determine theme based on Hawaii time
    const now = new Date();
    const hawaiiDate = new Date(now.toLocaleString('en-US', { timeZone: 'Pacific/Honolulu' }));
    const hours = hawaiiDate.getHours();
    return hours >= 6 && hours < 18 ? 'light' : 'dark';
  });

  // Get colors based on current mode
  const colors = mode === 'light' ? lightTheme : darkTheme;

  // Notify all iframes about theme change
  const notifyIframes = (themeMode: ThemeMode) => {
    try {
      const iframes = document.querySelectorAll('iframe');
      iframes.forEach(iframe => {
        if (iframe.contentWindow) {
          iframe.contentWindow.postMessage({ theme: themeMode }, '*');
        }
      });
    } catch (e) {
      console.log('Error notifying iframes', e);
    }
  };

  // Toggle theme function
  const toggleTheme = () => {
    setMode(prevMode => {
      const newMode = prevMode === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', newMode);
      notifyIframes(newMode);
      return newMode;
    });
  };

  // Apply theme to body when mode changes
  useEffect(() => {
    document.body.setAttribute('data-theme', mode);
    notifyIframes(mode);
  }, [mode]);

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme, colors }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider; 