import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../../styles/theme';

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  padding: 4px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const ToggleButton = styled.button<{ isActive: boolean }>`
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 0;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.05);
  }
  
  &:focus {
    outline: none;
  }
`;

const CelestialBody = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  transition: all 0.4s ease;
  background: ${props => props.isActive
    ? 'linear-gradient(45deg, #2a2a2a 0%, #3a3a3a 40%, #444444 100%)'  // Moon texture
    : 'radial-gradient(circle at 30% 30%, #FFD700, #FFA500)'};  // Sun gradient
  box-shadow: ${props => props.isActive
    ? 'inset -1px 1px 0px 0px rgba(0,0,0,0.3), 0 0 2px rgba(255,255,255,0.1)'  // Moon shadow
    : 'inset 0 0 8px rgba(255, 215, 0, 0.3), 0 0 4px rgba(255, 165, 0, 0.2)'};  // Sun glow
`;

// Moon craters with more realistic placement
const Crater = styled.div<{ size: string, top: string, left: string, opacity: string, isActive: boolean }>`
  position: absolute;
  width: ${props => props.size};
  height: ${props => props.size};
  background-color: #1a1a1a;
  border-radius: 50%;
  top: ${props => props.top};
  left: ${props => props.left};
  opacity: ${props => props.isActive ? props.opacity : 0};
  transition: opacity 0.4s ease;
  box-shadow: inset 1px 1px 1px rgba(0,0,0,0.4), 0 0 1px rgba(255,255,255,0.1);
`;

// Sun rays
const SunRay = styled.div<{ isActive: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: ${props => props.isActive ? 0 : 1};
  transition: opacity 0.4s ease;
  
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(255, 215, 0, 0.15) 0%, transparent 70%);
    transform: translate(-50%, -50%);
    animation: rotate 20s linear infinite;
  }
  
  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

const ThemeToggle: React.FC = () => {
  const { mode, toggleTheme } = useTheme();
  const isDark = mode === 'dark';

  return (
    <ToggleContainer>
      <ToggleButton 
        isActive={isDark} 
        onClick={toggleTheme} 
        aria-label={`Switch to ${isDark ? 'light' : 'dark'} mode`}
      >
        <CelestialBody isActive={isDark}>
          {/* Realistic moon craters */}
          <Crater size="2px" top="5px" left="6px" opacity="0.8" isActive={isDark} />
          <Crater size="1px" top="10px" left="14px" opacity="0.6" isActive={isDark} />
          <Crater size="1.5px" top="16px" left="8px" opacity="0.7" isActive={isDark} />
          <Crater size="0.8px" top="8px" left="15px" opacity="0.5" isActive={isDark} />
          <Crater size="1px" top="14px" left="4px" opacity="0.6" isActive={isDark} />
          
          {/* Sun rays */}
          <SunRay isActive={isDark} />
        </CelestialBody>
      </ToggleButton>
    </ToggleContainer>
  );
};

export default ThemeToggle; 