import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import ContactModal from '../components/ui/ContactModal';

// Import local images
import babyUsImage from '../assets/images/baby us.jpeg';
import designTeachingImage from '../assets/images/design-teaching.jpeg';
import artSchoolImage from '../assets/images/art-school.jpeg';
import meInHawaiiImage from '../assets/images/me-in-hawaii.jpg';

// Import typography journey images
const typographyContent = [
  {
    src: '/assets/images/type/first.JPG',
    alt: 'Typography journey - First steps',
    type: 'image'
  },
  {
    src: '/assets/images/type/second.jpg',
    alt: 'Typography journey - Learning process',
    type: 'image'
  },
  {
    src: '/assets/images/type/third.JPG',
    alt: 'Typography journey - Advanced studies',
    type: 'image'
  },
  {
    src: '/assets/images/type/matthew-carter.JPG',
    alt: 'Meeting with Matthew Carter',
    type: 'image'
  },
  {
    src: '/assets/images/type/sketch.webm',
    alt: 'Typography sketching process',
    type: 'video',
    mediaType: 'video/webm'
  }
];

const ImageCarousel = styled.div`
  position: relative;
  width: 100%;
  max-width: 450px;
  height: 450px;
  overflow: hidden;
  border-radius: 16px;
  
  @media (max-width: 991px) {
    height: 350px;
    max-width: 100%;
  }
`;

const CarouselSlide = styled.img<{ isActive: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${props => props.isActive ? 1 : 0};
  transition: opacity 0.8s ease-in-out;
  border-radius: 16px;
`;

const CarouselVideo = styled.video<{ isActive?: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${props => props.isActive ? 1 : 0};
  transition: opacity 0.8s ease-in-out;
  border-radius: 16px;
  background: var(--background);
`;

const Overlay = styled.div<{ isActive: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  left: 0;
  opacity: ${props => props.isActive ? 1 : 0};
  transition: opacity 0.8s ease-in-out;
  border-radius: 16px;
  background: var(--background);
`;

// Styled Components
const PortfolioContainer = styled.div`
  background-color: var(--background);
  padding-top: 25px !important;
  overflow: hidden;
  max-width: 1160px;
  margin: 0 auto;
  padding: 25px 20px 0;
  
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AboutSection = styled.section`
  margin: 60px 0 40px;
  width: 100%;
  
  @media (max-width: 991px) {
    margin: 40px 0 30px;
  }
`;

const SectionTitle = styled.h2`
  color: var(--secondary-text);
  font-size: 21px;
  font-weight: 590;
  margin-bottom: 16px;
  font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  
  @media (max-width: 991px) {
    font-size: 18px;
    margin-bottom: 12px;
  }
`;

const AboutDescription = styled.p`
  font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  font-size: 53px;
  font-weight: 590;
  margin-bottom: 16px;
  line-height: 1.2;
  color: var(--text);
  max-width: 95%;
  
  @media (max-width: 991px) {
    font-size: 36px;
    line-height: 1.3;
    max-width: 100%;
  }
`;

const BiographyLayout = styled.div`
  width: 100%;
  margin-top: 80px;
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
  
  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  position: relative;
  min-height: 500px;
  
  @media (max-width: 991px) {
    flex-direction: column;
    margin-top: 20px;
    min-height: auto;
  }
`;

const TimelineNav = styled.nav`
  width: 300px;
  flex-shrink: 0;
  padding-right: 40px;
  display: flex;
  align-items: center;
  min-height: 500px;
  
  @media (max-width: 991px) {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    padding-right: 0;
    min-height: auto;
  }
`;

const TimelineList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  
  @media (max-width: 991px) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 25px;
    padding-bottom: 10px;
    
    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(183, 183, 183, 0.5);
      border-radius: 4px;
    }
  }
`;

const TimelineItem = styled.li<{ isActive: boolean }>`
  position: relative;
  color: ${props => props.isActive ? 'var(--text)' : 'var(--secondary-text)'};
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  padding-left: ${props => props.isActive ? '24px' : '0'};
  white-space: nowrap;
  display: flex;
  align-items: center;
  min-height: 24px;
  
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${props => props.isActive ? '0' : '-20px'};
    opacity: ${props => props.isActive ? '1' : '0'};
    color: var(--text);
    width: 18px;
    height: 18px;
    transition: all 0.3s ease;
    pointer-events: none;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    
    path {
      stroke: currentColor;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: none;
    }
  }
  
  &:hover {
    padding-left: ${props => props.isActive ? '24px' : '24px'};
  }
  
  &:hover svg {
    opacity: 0.5;
    left: 0;
  }
  
  @media (max-width: 991px) {
    font-size: 16px;
    margin-bottom: 0;
    padding-left: ${props => props.isActive ? '24px' : '0'};
    
    &:hover {
      padding-left: ${props => props.isActive ? '24px' : '24px'};
    }
    
    svg {
      width: 16px;
      height: 16px;
      left: ${props => props.isActive ? '0' : '-20px'};
      margin-top: -1px;
    }
    
    &:hover svg {
      opacity: 0.5;
      left: 0;
    }
  }
`;

const BiographySection = styled.section`
  flex: 1;
  max-width: 960px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 500px;
  
  @media (max-width: 991px) {
    width: 100%;
    min-height: auto;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 35px;
  width: 100%;
  align-items: center;
  
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0;
  
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const BiographyImage = styled.img`
  width: 100%;
  max-width: 450px;
  height: auto;
  object-fit: cover;
  border-radius: 16px;
  
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const BiographyText = styled.p`
  color: var(--text);
  font-size: 21px;
  line-height: 1.6;
  font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  font-weight: 400;
  margin: 0;
  max-width: 95%;
  
  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 1.7;
    max-width: 100%;
  }
`;

const GallerySection = styled.section`
  width: 100%;
  margin: 40px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const GalleryImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 16px;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.02);
  }
  
  @media (max-width: 991px) {
    height: 250px;
  }
  
  @media (max-width: 576px) {
    height: 350px;
  }
`;

const CarouselImage = styled.img<{ isActive: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${props => props.isActive ? 1 : 0};
  transition: opacity 0.8s ease-in-out;
  border-radius: 16px;
  background: var(--background);
`;

const StoryPage: React.FC = () => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [currentTimelineIndex, setCurrentTimelineIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    let imageInterval: NodeJS.Timeout;
    
    // Only start the interval if we're on the Typography section
    if (timelineContent[currentTimelineIndex].title === 'Typography Journey') {
      imageInterval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => {
          // If current item is video, wait longer
          const currentItem = typographyContent[prevIndex];
          if (currentItem.type === 'video') {
            // Let video play for longer (8 seconds)
            return prevIndex;
          }
          return prevIndex === typographyContent.length - 1 ? 0 : prevIndex + 1;
        });
      }, 5000); // Changed from 3000 to 5000 (5 seconds for images)
    }

    return () => {
      if (imageInterval) {
        clearInterval(imageInterval);
      }
    };
  }, [currentTimelineIndex, currentImageIndex]);

  // Content for each timeline phase
  const timelineContent = [
    {
      title: 'Early Curiosity',
      image: babyUsImage,
      imageAlt: 'Me and my little brother in Istanbul, 1998',
      text: 'Growing up, I had an insatiable curiosity for how things worked. From fixing a tube TV at age 10 to exploring creative spaces, my childhood was filled with hands-on learning experiences. My father, a designer, introduced me to the world of design agencies, where I was captivated by the creative energy and problem-solving mindset.'
    },
    {
      title: 'First Steps in Design',
      image: '/assets/images/photoshop.avif',
      imageAlt: 'Learning Photoshop and design tools',
      text: 'At 14, I convinced my father to enroll me in a 6-month graphic and web design course. As the youngest student, I absorbed everything I could learn. This led to creating my high school\'s first website and handling various digital projects. Every opportunity to create became a stepping stone in my journey.'
    },
    {
      title: 'Engineering Mind',
      image: '/assets/images/engineer-mind.jpg',
      imageAlt: 'Early computer engineer working with ENIAC',
      text: 'I chose Mathematical Engineering at Yildiz Technical University to train both sides of my brain. While continuing my design journey, the rigorous program taught me analytical thinking, problem-solving, and coding fundamentals. This unique combination of creative and technical skills would later prove invaluable in my product design career, allowing me to bridge the gap between design vision and technical implementation.'
    },
    {
      title: 'The Silicon Valley Dream',
      image: '/assets/images/young-steve-jobs-1_0.jpg',
      imageAlt: 'Young Steve Jobs with the first Macintosh',
      text: 'During my engineering studies, reading "The Secret Life of Steve Jobs" was a turning point. It sparked my dream of working in Silicon Valley - a seemingly impossible goal for a young boy in Turkey. Armed with my engineering background and design skills, I was determined to make this dream a reality.'
    },
    {
      title: 'Journey to America',
      image: artSchoolImage,
      imageAlt: 'Life drawing from my time in art school',
      text: 'In the winter of 2013, I arrived in New York, completely unprepared for the bone-chilling cold. Despite wanting to return home many times, I persevered. While studying Fine Arts, I worked at a New Jersey startup building B2B products. This experience led to an opportunity with a Silicon Valley startup, where I helped build iOS apps that reached 5M downloads. Along the way, I\'ve been teaching UX/UI design and mentoring designers globally through ADPlist.'
    },
    {
      title: 'Typography Journey',
      images: typographyContent,
      imageAlt: 'Typography and type design journey',
      text: 'My love for typography began when I discovered "Just My Type" - a book that ignited my passion for letterforms. This passion led me across five different states, attending typography conferences where I met legendary designers like Matthew Carter (creator of Verdana), Roger Black (The Guardian\'s designer), and Lance Wyman. I volunteered at the Type Directors Club in NYC - a place where Steve Jobs once frequented. After years of preparation and persistence, I was accepted into the prestigious type design program at Letterform Archive. My expertise in typography even led to an invitation as a guest lecturer at Stanford University.'
    },
    {
      title: 'Present Day',
      image: meInHawaiiImage,
      imageAlt: 'Living in Hawaii | 2022',
      text: 'Today, I combine my technical background with creative vision to build products that reach millions. I\'ve completed a post-graduate program in Type Design from Type West, developed successful branding projects, and continue to explore the intersection of data, innovation, and storytelling. When not designing, I\'m traveling across the US, documenting museum visits, and sharing my journey with others.'
    }
  ];

  const renderCarouselItem = (item: any, index: number) => {
    if (item.type === 'video') {
      return (
        <CarouselVideo
          key={index}
          autoPlay
          muted
          loop
          playsInline
          isActive={index === currentImageIndex}
          onEnded={() => {
            // Move to next slide when video ends
            setCurrentImageIndex(index === typographyContent.length - 1 ? 0 : index + 1);
          }}
        >
          <source src={item.src} type={item.mediaType} />
        </CarouselVideo>
      );
    }
    return (
      <CarouselSlide
        key={index}
        src={item.src}
        alt={item.alt}
        isActive={index === currentImageIndex}
        loading="eager"
      />
    );
  };

  const renderImage = (content: any) => {
    if (content.title === 'Typography Journey') {
      return (
        <ImageCarousel>
          {typographyContent.map((item, index) => renderCarouselItem(item, index))}
        </ImageCarousel>
      );
    }
    return (
      <BiographyImage 
        src={content.image}
        alt={content.imageAlt}
        className="biography-image"
      />
    );
  };

  return (
    <Layout onContactClick={() => setIsContactModalOpen(true)}>
      <PortfolioContainer className="portfolio-container">
        <AboutSection className="about-section">
          <SectionTitle className="section-title">About Me</SectionTitle>
          <AboutDescription className="about-description">
            A self-taught designer who blended mathematical engineering with
            artistic vision to build products reaching millions, now leading
            product development while mentoring designers globally and pursuing
            extensive travel.
          </AboutDescription>
        </AboutSection>

        <BiographyLayout className="biography-layout">
          <ContentWrapper>
            <TimelineNav className="timeline-nav">
              <TimelineList className="timeline-list">
                {timelineContent.map((item, index) => (
                  <TimelineItem 
                    key={index} 
                    isActive={index === currentTimelineIndex}
                    className={`timeline-item ${index === currentTimelineIndex ? 'active' : ''}`}
                    onClick={() => {
                      setCurrentTimelineIndex(index);
                      setCurrentImageIndex(0);
                    }}
                  >
                    {item.title}
                    <svg viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"/>
                    </svg>
                  </TimelineItem>
                ))}
              </TimelineList>
            </TimelineNav>

            <BiographySection className="biography-section">
              <ContentContainer className="content-container">
                <ImageContainer className="image-container">
                  {renderImage(timelineContent[currentTimelineIndex])}
                </ImageContainer>
                
                <TextContainer className="text-container">
                  <BiographyText className="biography-text">
                    {timelineContent[currentTimelineIndex].text}
                  </BiographyText>
                </TextContainer>
              </ContentContainer>
            </BiographySection>
          </ContentWrapper>
        </BiographyLayout>

        <GallerySection>
          <GalleryImage 
            src="/assets/images/personal/gallery.JPG" 
            alt="Personal gallery image 1"
            loading="lazy"
          />
          <GalleryImage 
            src="/assets/images/personal/gallery1.JPG" 
            alt="Personal gallery image 2"
            loading="lazy"
          />
          <GalleryImage 
            src="/assets/images/personal/gallery2.JPG" 
            alt="Personal gallery image 3"
            loading="lazy"
          />
          <GalleryImage 
            src="/assets/images/personal/gallery3.JPG" 
            alt="Personal gallery image 4"
            loading="lazy"
          />
        </GallerySection>
      </PortfolioContainer>
      <ContactModal 
        isOpen={isContactModalOpen} 
        onClose={() => setIsContactModalOpen(false)} 
      />
    </Layout>
  );
};

export default StoryPage; 