import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LAYOUT, BREAKPOINTS } from '../styles/layout';

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: var(--background);
`;

const Header = styled.header`
  width: 100%;
  max-width: ${LAYOUT.maxWidth};
  margin: 0 auto;
  padding: 25px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--background);
  z-index: 1000;
`;

const BackLink = styled(Link)`
  color: var(--text);
  text-decoration: none;
  font-size: 21px;
  font-weight: 590;
  font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  display: flex;
  align-items: center;
  gap: 8px;
  
  svg {
    width: 21px;
    height: 21px;
    transition: transform 0.2s ease;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
  }
  
  &:hover svg {
    transform: translateX(-4px);
  }
  
  @media (max-width: ${BREAKPOINTS.mobile}) {
    font-size: 18px;
    
    svg {
      width: 18px;
      height: 18px;
      margin-top: -1px;
    }
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 71px;
  
  iframe {
    width: 100%;
    height: calc(100vh - 71px);
    border: none;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background);
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s ease;
  
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
`;

const PlatosHatPage: React.FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleIframeLoad = () => {
      if (iframeRef.current) {
        iframeRef.current.style.opacity = '1';
        setIsLoading(false);
      }
    };

    if (iframeRef.current) {
      iframeRef.current.addEventListener('load', handleIframeLoad);
    }

    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', handleIframeLoad);
      }
    };
  }, []);

  return (
    <PageContainer>
      <Header>
        <BackLink to="/">
          <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M19 12H5M12 19l-7-7 7-7"/>
          </svg>
          Back
        </BackLink>
      </Header>
      <ContentContainer>
        <LoadingOverlay className={isLoading ? '' : 'hidden'} />
        <iframe 
          ref={iframeRef}
          src={`${process.env.PUBLIC_URL}/platoshat/index.html`}
          title="Plato's Hat Process"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        />
      </ContentContainer>
    </PageContainer>
  );
};

export default PlatosHatPage; 