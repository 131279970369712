import { createContext, useContext } from 'react';

export type ThemeMode = 'light' | 'dark';

export interface ThemeColors {
  background: string;
  text: string;
  secondaryText: string;
  divider: string;
  buttonBackground: string;
  buttonText: string;
}

export interface ThemeContextType {
  mode: ThemeMode;
  toggleTheme: () => void;
  colors: ThemeColors;
}

// Soft light theme colors
export const lightTheme: ThemeColors = {
  background: '#f8f8f8', // Softer than pure white
  text: '#000000',       // Pure black for better contrast
  secondaryText: '#777777',
  divider: 'rgba(183, 183, 183, 1)',
  buttonBackground: '#eeeeee',
  buttonText: '#000000',  // Pure black for buttons too
};

// Soft dark theme colors
export const darkTheme: ThemeColors = {
  background: '#222222', // Softer than pure black
  text: '#ffffff',       // Pure white for better contrast
  secondaryText: '#aaaaaa',
  divider: 'rgba(70, 70, 70, 1)',
  buttonBackground: '#444444',
  buttonText: '#ffffff',  // Pure white for buttons too
};

// Create theme context with default values
export const ThemeContext = createContext<ThemeContextType>({
  mode: 'light',
  toggleTheme: () => {},
  colors: lightTheme,
});

// Theme hook for easy access
export const useTheme = () => useContext(ThemeContext); 