import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const SketchContainer = styled.div`
  width: 100%;
  height: 700px;
  border-radius: 12px;
  overflow: hidden;
  background: var(--background);
  position: relative;
  
  @media (max-width: 800px) {
    height: 600px;
  }
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 12px;
  background: white;
`;

const TypeCookerSketch: React.FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const handleIframeLoad = () => {
      if (iframeRef.current) {
        try {
          // Try to access the iframe content to verify it loaded
          const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow?.document;
          if (!iframeDoc) {
            console.error('Could not access iframe content');
          }
          
          // Force white background on iframe body
          if (iframeDoc?.body) {
            iframeDoc.body.style.backgroundColor = 'white';
          }
          
          // Try to update iframe theme
          try {
            const iframeWindow = iframeRef.current.contentWindow;
            if (iframeWindow) {
              const theme = document.body.getAttribute('data-theme');
              iframeWindow.postMessage({ theme }, '*');
            }
          } catch (e) {
            console.log('Cannot communicate with iframe:', e);
          }
        } catch (error) {
          console.error('Error loading iframe:', error);
        }
      }
    };

    if (iframeRef.current) {
      iframeRef.current.addEventListener('load', handleIframeLoad);
    }
    
    // Listen for theme changes
    const handleThemeChange = () => {
      if (iframeRef.current?.contentWindow) {
        const theme = document.body.getAttribute('data-theme');
        iframeRef.current.contentWindow.postMessage({ theme }, '*');
      }
    };
    
    // MutationObserver to watch for theme attribute changes on the body
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'data-theme'
        ) {
          handleThemeChange();
        }
      });
    });
    
    observer.observe(document.body, { attributes: true });

    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', handleIframeLoad);
      }
      observer.disconnect();
    };
  }, []);

  return (
    <SketchContainer>
      <StyledIframe 
        ref={iframeRef}
        src="/typecooker/index.html"
        title="Word Cooker"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        loading="lazy"
      />
    </SketchContainer>
  );
};

export default TypeCookerSketch; 