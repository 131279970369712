import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

// Note: We're using !important to make sure these styles override any parent component styling
const StyledTextarea = styled.textarea<{ fontSize?: string | number; fontFamily?: string }>`
  width: 100%;
  min-height: 150px;
  margin-top: 30px;
  padding: 20px;
  border: 1px solid rgba(221, 221, 221, 0.5) !important;
  border-radius: 8px;
  background: var(--background) !important;
  color: var(--text) !important;
  font-size: ${props => Number(props.fontSize) || 24}px !important;
  font-family: ${props => props.fontFamily || '"Panoptikon", -apple-system, Roboto, Helvetica, sans-serif'} !important;
  font-weight: 400;
  line-height: 1.3;
  resize: vertical;
  outline: none;
  transition: border-color 0.3s, font-size 0.2s;
  
  &:focus {
    outline: none;
    border-color: var(--divider) !important;
  }
  
  &::placeholder {
    color: var(--secondary-text) !important;
  }
  
  @media (max-width: 991px) {
    font-size: ${props => Math.min(Number(props.fontSize) || 24, 28)}px !important;
  }
`;

interface AnimatedTextareaProps {
  fontSize?: string | number;
  fontFamily?: string;
  placeholder?: string;
  className?: string;
  spellCheck?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
}

const AnimatedTextarea: React.FC<AnimatedTextareaProps> = ({
  fontSize,
  fontFamily,
  placeholder = "Type something here to try the font...",
  className,
  spellCheck = false,
  onChange,
  value: externalValue
}) => {
  // Start with "Try Me" text
  const [text, setText] = useState("Try Me");
  const [typeIndex, setTypeIndex] = useState(0);
  const [charIndex, setCharIndex] = useState("Try Me".length);
  const [isDeleting, setIsDeleting] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  
  // Ensure fontSize is always a number
  const numericFontSize = Number(fontSize) || 24;
  
  const quotes = [
    "Design is intelligence made visible.",
    "Typography is the voice of your thoughts in silence.",
    "Simplicity is the ultimate sophistication.",
    "Every great design begins with an even better story.",
    "Good design is obvious. Great design is transparent.",
    "Design creates culture. Culture shapes values.",
    "Type is a voice; let it be heard.",
    "Details make perfection, and perfection is not a detail.",
    "Design is thinking made visual.",
    "In design, less isn't more. Just enough is more.",
    "The details are not the details. They make the design.",
    "White space is to be regarded as an active element, not a passive background."
  ];

  // Animation timing control
  useEffect(() => {
    // If user has provided value or interacted, stop animation
    if (externalValue !== undefined) {
      setText(externalValue);
      return;
    }
    
    if (userInteracted) {
      return;
    }
    
    // Initial pause before starting the cycle (only for "Try Me")
    if (text === "Try Me" && !isDeleting) {
      const timer = setTimeout(() => {
        setIsDeleting(true);
      }, 1500);
      return () => clearTimeout(timer);
    }
    
    let timer: ReturnType<typeof setTimeout>;
    
    if (isDeleting) {
      // Delete one character at a time
      if (charIndex > 0) {
        timer = setTimeout(() => {
          setCharIndex(charIndex - 1);
          setText(text.substring(0, charIndex - 1));
        }, 30); // Faster deletion
      } else {
        // When fully deleted, move to next state
        timer = setTimeout(() => {
          // If we just deleted "Try Me", start with quotes
          if (typeIndex === 0 && text === "") {
            setTypeIndex(0);
            setIsDeleting(false);
          } else {
            // Move to next quote
            const nextIndex = (typeIndex + 1) % quotes.length;
            setTypeIndex(nextIndex);
            setIsDeleting(false);
          }
        }, 500); // Pause before typing next text
      }
    } else {
      const currentContent = typeIndex === 0 && text === "" ? quotes[0] : quotes[typeIndex];
      
      // Type one character at a time
      if (charIndex < currentContent.length) {
        timer = setTimeout(() => {
          setCharIndex(charIndex + 1);
          setText(currentContent.substring(0, charIndex + 1));
        }, 70); // Speed of typing
      } else {
        // When fully typed, pause before deleting
        timer = setTimeout(() => {
          setIsDeleting(true);
        }, 2000); // Pause before starting to delete
      }
    }
    
    return () => clearTimeout(timer);
  }, [charIndex, isDeleting, typeIndex, quotes, userInteracted, externalValue, text]);

  // Update the textarea's font size directly when fontSize prop changes
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.fontSize = `${numericFontSize}px`;
    }
  }, [numericFontSize]);

  const handleFocus = () => {
    if (!userInteracted) {
      setText("");
      setUserInteracted(true);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  const handleClick = () => {
    if (!userInteracted) {
      setUserInteracted(true);
      setText("");
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }
  };

  return (
    <StyledTextarea
      ref={textareaRef}
      fontSize={numericFontSize}
      fontFamily={fontFamily}
      placeholder={placeholder}
      className={className}
      spellCheck={spellCheck}
      value={text}
      onChange={handleChange}
      onFocus={handleFocus}
      onClick={handleClick}
    />
  );
};

export default AnimatedTextarea; 