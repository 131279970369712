import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import HomePage from './pages/HomePage';
import StoryPage from './pages/StoryPage';
import PlatosHatPage from './pages/PlatosHatPage';
import FontLoader from './components/common/FontLoader';
import ThemeProvider from './components/theme/ThemeProvider';
import './App.css';
import './styles/fonts.css';

const AppContainer = styled.div`
  font-family: 'Poppins', sans-serif;
`;

function App() {
  return (
    <ThemeProvider>
      <FontLoader>
        <AppContainer>
          <Router>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/story" element={<StoryPage />} />
              <Route path="/platoshat" element={<PlatosHatPage />} />
            </Routes>
          </Router>
        </AppContainer>
      </FontLoader>
    </ThemeProvider>
  );
}

export default App;
