import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  /* Font declarations */
  @font-face {
    font-family: 'SF Pro';
    src: url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'SF Pro';
    src: url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'SF Pro';
    src: url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-semibold-webfont.woff2') format('woff2');
    font-weight: 590;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'SF Pro';
    src: url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-bold-webfont.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  /* Theme variables for light mode (default) */
  :root {
    --background: #f8f8f8;
    --background-darker: #e0e0e0;
    --text: #000000;
    --secondary-text: #777777;
    --divider: rgba(183, 183, 183, 1);
    --button-bg: #eeeeee;
    --button-text: #000000;
  }

  /* Theme variables for dark mode */
  [data-theme="dark"] {
    --background: #222222;
    --background-darker: #1a1a1a;
    --text: #ffffff;
    --secondary-text: #aaaaaa;
    --divider: rgba(70, 70, 70, 1);
    --button-bg: #444444;
    --button-text: #ffffff;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  html {
    font-size: 16px;
    
    @media (max-width: 991px) {
      font-size: 15px;
    }
    
    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
  
  body {
    font-family: 'SF Pro', -apple-system, Roboto, Helvetica, sans-serif;
    background-color: var(--background);
    color: var(--text);
    line-height: 1.5;
    overflow-x: hidden;
    width: 100%;
    min-height: 100vh;
    -webkit-text-size-adjust: 100%;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  
  a {
    text-decoration: none;
    color: inherit;
    -webkit-tap-highlight-color: transparent;
  }
  
  button {
    background: none;
    border: none;
    cursor: pointer;
    font-family: inherit;
    -webkit-tap-highlight-color: transparent;
    
    &:focus {
      outline: none;
    }
  }
  
  /* Utility classes */
  .hide-mobile {
    @media (max-width: 991px) {
      display: none !important;
    }
  }
  
  .show-mobile {
    display: none !important;
    
    @media (max-width: 991px) {
      display: block !important;
    }
  }
  
  .container {
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
    padding: 0 20px;
    
    @media (max-width: 991px) {
      padding: 0 20px;
    }
  }
  
  /* Touch device optimizations */
  @media (hover: none) {
    * {
      cursor: default !important;
    }
    
    a:active,
    button:active {
      opacity: 0.7;
    }
  }
  
  /* Smooth scrolling */
  html {
    scroll-behavior: smooth;
  }
  
  /* Better focus styles */
  :focus {
    outline: 2px solid rgba(0, 119, 255, 0.5);
    outline-offset: 2px;
  }
  
  :focus:not(:focus-visible) {
    outline: none;
  }
  
  /* Prevent text size adjustment on orientation change */
  html {
    -webkit-text-size-adjust: 100%;
  }
  
  /* Remove tap highlight on iOS */
  * {
    -webkit-tap-highlight-color: transparent;
  }
  
  /* Improved mobile form elements */
  input,
  textarea,
  select {
    font-size: 16px; /* Prevents zoom on focus in iOS */
    
    &:focus {
      outline: none;
    }
  }
  
  /* Portfolio Web Design */
  .portfolio-web-design {
    background-color: var(--background);
    padding-top: 0;
    padding-bottom: 526px;
    overflow: hidden;
  }
  
  @media (max-width: 991px) {
    .portfolio-web-design {
      padding-top: 0;
      padding-bottom: 100px;
    }
  }
  
  .container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    max-width: 1160px;
    margin: 0 auto;
  }
  
  @media (max-width: 991px) {
    .container {
      max-width: 100%;
    }
  }
  
  /* Intro Section */
  .intro-section {
    width: 100%;
    max-width: 100%;
    font-family: SF Pro, -apple-system, Roboto, Helvetica, sans-serif;
    font-weight: 590;
  }
  
  .intro-content {
    width: 100%;
  }
  
  @media (max-width: 991px) {
    .intro-content {
      max-width: 100%;
    }
  }
  
  .intro-text {
    color: var(--text);
    font-size: 63px;
    margin: 0;
  }
  
  @media (max-width: 991px) {
    .intro-text {
      max-width: 100%;
      font-size: 28px;
    }
  }
  
  .read-more-link {
    color: var(--secondary-text);
    font-size: 21px;
    font-family: SF Pro, -apple-system, Roboto, Helvetica, sans-serif;
    font-weight: 590;
    margin-top: 30px;
    display: block;
    text-decoration: none;
  }
  
  @media (max-width: 991px) {
    .read-more-link {
      max-width: 100%;
      margin-top: 20px;
    }
  }
  
  /* Contact Bar */
  .contact-bar {
    margin-top: 46px;
    width: 100%;
    max-width: 1160px;
    white-space: nowrap;
  }
  
  @media (max-width: 991px) {
    .contact-bar {
      max-width: 100%;
      margin-top: 40px;
      white-space: initial;
    }
  }
  
  .divider {
    background-color: rgb(226 226 226);
    height: 1px;
    width: 100%;
    z-index: 10;
    flex-shrink: 0;
    margin: 0;
    border: none;
  }
  
  @media (max-width: 991px) {
    .divider {
      max-width: 100%;
    }
  }
  
  .contact-info {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  @media (max-width: 991px) {
    .contact-info {
      max-width: 100%;
      white-space: initial;
      margin-bottom: 30px;
    }
  }
  
  .contact-heading {
    color: var(--text);
    font-size: 21px;
    margin: 0;
    cursor: pointer;
    position: relative;
    transition: color 0.2s ease;
  }
  
  .contact-heading:hover {
    color: var(--secondary-text);
  }
  
  .contact-links {
    display: flex;
    align-items: center;
    gap: 30px;
    font-size: 25px;
    color: rgba(183, 183, 183, 1);
  }
  
  @media (max-width: 991px) {
    .contact-links {
      white-space: initial;
      gap: 20px;
    }
  }
  
  .contact-link {
    color: rgba(183, 183, 183, 1);
    text-decoration: none;
  }
  
  /* Project Section */
  .project-section {
    display: flex;
    margin-top: 120px;
    width: 1160px;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }
  
  @media (max-width: 991px) {
    .project-section {
      margin-top: 80px;
    }
  }
  
  .project-title {
    color: var(--text);
    font-size: 46px;
    font-family: Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    font-weight: 700;
    align-self: start;
    margin: 0;
  }
  
  @media (max-width: 991px) {
    .project-title {
      max-width: 100%;
      font-size: 28px;
    }
  }
  
  .project-description {
    color: var(--text);
    font-size: 32px;
    font-family: SF Pro, -apple-system, Roboto, Helvetica, sans-serif;
    font-weight: 274;
    line-height: 45px;
    margin-top: 40px;
    margin-bottom: 0;
  }
  
  @media (max-width: 991px) {
    .project-description {
      max-width: 100%;
      margin-top: 30px;
      font-size: 21px;
      line-height: 32px;
    }
  }
  
  /* About page styles */
  .portfolio-container {
    background-color: var(--background);
    padding-top: 144px;
    overflow: hidden;
  }
  
  .portfolio-main {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    max-width: 1160px;
    margin: 0 auto;
  }
  
  @media (max-width: 991px) {
    .portfolio-main {
      max-width: 100%;
      padding: 0 20px;
    }
  }
  
  /* Header styles */
  .portfolio-header {
    width: 100%;
    max-width: 1160px;
    position: relative;
    padding-bottom: 0;
    margin-bottom: 35px;
  }
  
  .header-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
  }
  
  .back-link {
    color: var(--text);
    text-decoration: none;
    font-size: 21px;
    font-weight: 590;
    font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .designer-name {
    font-weight: 590;
    font-size: 21px;
    color: var(--text);
    text-align: right;
    font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  /* About section */
  .about-section {
    margin: 60px 0 40px;
    width: 100%;
    max-width: 1160px;
  }
  
  .section-title {
    color: rgba(183, 183, 183, 1);
    font-size: 21px;
    font-weight: 590;
    margin-bottom: 16px;
    font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .about-description {
    font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
    font-size: 53px;
    font-weight: 590;
    margin-bottom: 16px;
    line-height: 1.2;
    color: var(--text);
  }
  
  @media (max-width: 991px) {
    .portfolio-container {
      padding-top: 100px;
    }
    
    .about-section {
      margin: 40px 0 30px;
    }
    
    .about-description,
    .intro-text {
      font-size: 28px;
    }
    
    .back-link,
    .designer-name {
      font-size: 18px;
    }
  }
  
  /* Animation styles */
  .fade-in {
    animation: fadeIn 0.5s ease-in-out;
    opacity: 0;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Status dot */
  #statusDot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #4CAF50;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  #statusDot.dot-visible {
    opacity: 1;
  }
  
  #lastUpdatedTime {
    font-weight: 500;
  }
`;

export default GlobalStyles; 