import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface ContactModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isOpen ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: var(--background);
  padding: 2.5rem;
  border-radius: 12px;
  max-width: 500px;
  width: 90%;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  
  @media (max-width: 991px) {
    padding: 2rem;
    width: 95%;
  }
  
  @media (max-width: 480px) {
    padding: 1.5rem;
    width: 100%;
    margin: 0 15px;
    border-radius: 8px;
  }
`;

const CloseButton = styled.span`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  @media (max-width: 480px) {
    top: 1rem;
    right: 1rem;
    font-size: 1.25rem;
  }
`;

const ModalTitle = styled.div`
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.3;
  
  @media (max-width: 991px) {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  @media (max-width: 480px) {
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
  }
`;

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  
  @media (max-width: 480px) {
    gap: 1.25rem;
  }
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  
  @media (max-width: 480px) {
    gap: 0.75rem;
  }
`;

const ContactIcon = styled.svg`
  width: 24px;
  height: 24px;
  color: var(--text);
  
  @media (max-width: 480px) {
    width: 20px;
    height: 20px;
  }
`;

const ContactLink = styled.a`
  text-decoration: none;
  color: var(--text);
  font-size: 1rem;
  font-weight: 400;
  transition: color 0.3s ease;
  
  &:hover {
    color: #0077ff;
  }
  
  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const DownloadButton = styled.a`
  display: inline-block;
  padding: 0.75rem 1.25rem;
  background-color: var(--button-bg);
  border-radius: 6px;
  text-decoration: none;
  color: var(--button-text);
  font-weight: 500;
  font-size: 1rem;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: var(--secondary-text);
    transform: translateY(-1px);
  }
  
  @media (max-width: 480px) {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
    width: 100%;
    text-align: center;
  }
`;

const ContactModal: React.FC<ContactModalProps> = ({ isOpen, onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  
  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };
    
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);
  
  // Handle ESC key
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    
    if (isOpen) {
      document.addEventListener('keydown', handleEsc);
    }
    
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, onClose]);
  
  return (
    <ModalOverlay isOpen={isOpen} id="contactModal" className="modal">
      <ModalContent ref={modalRef} className="modal-content">
        <CloseButton onClick={onClose} className="close-modal">&times;</CloseButton>
        <ModalTitle>Contact Me</ModalTitle>
        <ContactDetails className="contact-details">
          <ContactItem className="contact-item">
            <ContactIcon 
              className="contact-icon"
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
              <polyline points="22,6 12,13 2,6"></polyline>
            </ContactIcon>
            <ContactLink href="mailto:nurullah.cemil@gmail.com">nurullah.cemil@gmail.com</ContactLink>
          </ContactItem>
          <ContactItem className="contact-item">
            <ContactIcon 
              className="contact-icon"
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
            </ContactIcon>
            <ContactLink href="tel:2019895382">201-989-5382</ContactLink>
          </ContactItem>
          <ContactItem className="contact-item cv-download">
            <ContactIcon 
              className="contact-icon"
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="7 10 12 15 17 10"></polyline>
              <line x1="12" y1="15" x2="12" y2="3"></line>
            </ContactIcon>
            <DownloadButton href="Nurullah-Gokdogan-Resume.pdf" download className="download-cv-btn">Download Resume</DownloadButton>
          </ContactItem>
        </ContactDetails>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ContactModal; 