import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout/Layout';
import IntroSection from '../components/ui/IntroSection';
import ProjectCard from '../components/projects/ProjectCard';
import TypeDesignSection from '../components/projects/TypeDesignSection';
import ContactModal from '../components/ui/ContactModal';
import MuseoTagSketch from '../components/projects/MuseoTagSketch';
import TypeCookerSketch from '../components/projects/TypeCookerSketch';

const HomePage: React.FC = () => {
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);

  // Set up event listeners for the contact modal
  useEffect(() => {
    const contactHeadings = document.querySelectorAll('.contact-heading');
    const emailLinks = document.querySelectorAll('.contact-link');
    
    // Click handler for Contact heading
    const handleContactHeadingClick = (e: Event) => {
      e.preventDefault();
      setIsContactModalOpen(true);
    };
    
    // Click handler for Email link
    const handleEmailLinkClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.textContent === 'Email') {
        if (!e.ctrlKey && !e.metaKey && !e.shiftKey) {
          e.preventDefault();
          setIsContactModalOpen(true);
        }
      }
    };
    
    // Handler for custom openContactModal event
    const handleOpenContactModalEvent = () => {
      setIsContactModalOpen(true);
    };
    
    // Add event listeners
    contactHeadings.forEach(heading => {
      heading.addEventListener('click', handleContactHeadingClick);
    });
    
    emailLinks.forEach(link => {
      link.addEventListener('click', handleEmailLinkClick as EventListener);
    });
    
    // Listen for the custom event
    document.addEventListener('openContactModal', handleOpenContactModalEvent);
    
    // Cleanup
    return () => {
      contactHeadings.forEach(heading => {
        heading.removeEventListener('click', handleContactHeadingClick);
      });
      
      emailLinks.forEach(link => {
        link.removeEventListener('click', handleEmailLinkClick as EventListener);
      });
      
      // Remove custom event listener
      document.removeEventListener('openContactModal', handleOpenContactModalEvent);
    };
  }, []);

  return (
    <Layout onContactClick={() => setIsContactModalOpen(true)} showHeader={false}>
      <IntroSection />
      
      <ProjectCard 
        title="GoDaddy Invoicing Plus"
        description="Our team transformed GoDaddy's invoicing platform into a comprehensive solution for service businesses. Led the complete redesign of the user experience, focusing on streamlining customer management and payment workflows. The new system integrates with accounting software and reduces administrative time by 20%. Designed an intuitive interface that helps small businesses manage finances and get paid faster."
        role="Lead Designer"
        timeframe="2022 - Present"
        imageSrc="/assets/images/invoicing.png"
        imageAlt="GoDaddy Invoicing Plus"
        link="https://www.godaddy.com/payments/invoicing"
      />
      
      <ProjectCard 
        title="Pay Links"
        description="Our team, in collaboration with GoDaddy Airo, launched an innovative AI feature that creates payment pages through simple prompts. Led the design strategy and user experience, focusing on making AI technology approachable for small business owners. The system generates customized payment pages while providing smart previews and intuitive refinement options. Combined with features like next-business day payouts, Pay Links simplifies how small businesses get paid online."
        role="Lead Designer"
        timeframe="2022 - Present"
        imageSrc="/assets/images/paylinks.png"
        imageAlt="Pay Links"
        isRounded={true}
        link="https://www.godaddy.com/payments/pay-links"
      />
      
      <ProjectCard 
        title="Math Hero"
        description="Math Hero helps students learn mathematics through an innovative camera-based calculator app. Led the UX research with Gen Z students during COVID-19 and designed the complete UI and user flows. The solution pairs AI-powered problem recognition with short video explanations, making math concepts easier to understand. Crafted an engaging interface that resonates with younger users while maintaining educational clarity."
        role="Experience Designer / UI Designer"
        timeframe="iOS App • 2021"
        imageSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/75e1d590072e5e32cd0c4191c94a77df515e4ffc42f2543ae9046afc1cc2289d?placeholderIfAbsent=true&apiKey=45791709913447029a0f9f50a0a9387d"
        imageAlt="Math Hero"
        appStoreLink="https://apps.apple.com/us/app/math-ai-problem-solver-helper/id1565102390"
      />
      
      <ProjectCard 
        title="Baby Journal"
        description="Baby Journal helps mothers capture and memorialize their pregnancy journey through an intimate photojournal experience. Co-led the user experience design and created the complete visual identity, including over 2,000 custom stickers for photo embellishments. Designed all App Store visuals and marketing materials, making this a true end-to-end design project. The app enables beautiful photo editing, milestone tracking, and personalized storytelling for the motherhood journey."
        role="Experience Designer / UI Designer / Illustrator"
        timeframe="iOS App • 2021"
        imageSrc="/assets/images/baby-journal-first.png"
        imageAlt="Baby Journal"
        appStoreLink="https://apps.apple.com/us/app/baby-photo-editor-pic-journal/id1533928938"
      />
      
      <TypeDesignSection 
        title="Other Work • Type Design"
        description="Created Panoptikon with a bold vision: a typeface that makes museum exhibitions come alive! This high-contrast sans serif, crafted during my Type West post-graduate program, comes in three distinct flavors—bold, regular, and italic. The secret sauce? Distinctive concave stems that give it a dynamic punch while keeping things playful. It's my answer to breaking free from conventional exhibition typography, bringing a fresh voice to museum spaces."
        role="Type Designer"
        timeframe="2020"
        logoSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/ddf4912778d7a190ac16a007c0c8d1edec34789c362ba0389a24fcd233ee24c0?placeholderIfAbsent=true&apiKey=45791709913447029a0f9f50a0a9387d"
        logoAlt="Panoptikon Logo"
        typeSampleSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/6a6f8ee4c48d66be40c092de484f32ba6e93ea8fba784d05cc53c6b91e53bbb3?placeholderIfAbsent=true&apiKey=45791709913447029a0f9f50a0a9387d"
        typeSampleAlt="Panoptikon Type Sample"
        isDarkBackground={true}
      />
      
      <TypeDesignSection 
        title="Other Work • Type Design"
        description="What started as a funny mix-up at TypeWest turned into an amazing journey of reviving Caslon's Old Face! Started with blurry 600dpi scans (thinking I had to sketch everything from scratch—oops!) and dove deep into the fascinating world of optical adjustments. Discovered the quirky truth that curved shapes need more meat than straight lines, and those tiny bowls in lowercase letters? They taught me some of the best lessons in type design. Every challenge was a chance to learn something new about this historic typeface."
        role="Type Designer"
        timeframe="2019"
        logoSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/ddf4912778d7a190ac16a007c0c8d1edec34789c362ba0389a24fcd233ee24c0?placeholderIfAbsent=true&apiKey=45791709913447029a0f9f50a0a9387d"
        logoAlt="Plato's Hat Logo"
        typeSampleSrc="/assets/images/platoshat.svg"
        typeSampleAlt="Plato's Hat Type Sample"
        fontName="Plato's Hat"
        fontFamily='"Platos Hat", -apple-system, Roboto, Helvetica, sans-serif'
        isDarkBackground={false}
        typeSampleMaxWidth="600px"
        processLink="/platoshat"
      />
      
      <ProjectCard 
        title="Word Cooker"
        description="Cooked up this fun tool that makes type designers' lives easier! It's like a playground for testing typefaces, generating random words with all the tricky combinations—those tall ascenders, deep descenders, and fancy ligatures that need extra attention. Added some nifty toggles to mix and match letter combinations, plus a sketching template for the perfect testing recipe. Super happy to see it becoming a go-to resource in the type design community!"
        role="Developer"
        timeframe="2023"
        imageSrc=""
        imageAlt="Word Cooker"
        customImage={<TypeCookerSketch />}
        link="https://wordcooker.co"
        linkText="Go to the website"
      />
      
      <ProjectCard 
        title="Museo Tag"
        description="Had a blast creating this playful name tag generator that brings typography to life! Built with Processing, it lets letters dance and tumble with physics-based animation. Each letter falls and bounces with personality, creating unique name tags that are never boring. It's where my love for museums, typography, and interactive design came together in the most fun way possible."
        role="Developer"
        timeframe="2023"
        imageSrc=""
        imageAlt="Museo Tag"
        customImage={<MuseoTagSketch />}
      />
      
      <ProjectCard 
        title="Baby Journal Illustrations"
        description="Poured my heart into creating 2,000(!) custom stickers for the Baby Journal app. From tiny baby toes to big pregnancy milestones, every sticker was lovingly crafted to help moms tell their unique stories. Designed everything from adorable onesies to pregnancy cravings, first kicks to first steps. These illustrations became the heart of the app's personalization feature, letting moms add that perfect touch of whimsy to their precious memories."
        role="Illustrator"
        timeframe="Illustration Project for Baby Journal"
        imageSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/4cb755827523ff7c45fea23ac2b1f2683b3009648597afa35e38c050d251598c?placeholderIfAbsent=true&apiKey=45791709913447029a0f9f50a0a9387d"
        imageAlt="Baby Journal Illustrations"
      />
      
      <ContactModal 
        isOpen={isContactModalOpen} 
        onClose={() => setIsContactModalOpen(false)} 
      />
    </Layout>
  );
};

export default HomePage; 