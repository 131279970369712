import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../styles/layout';

interface AnimatedTypeSampleProps {
  className?: string;
  maxWidth?: string;
}

const Container = styled.div<{ maxWidth?: string }>`
  width: 100%;
  height: 400px;
  max-width: ${props => props.maxWidth || '100%'};
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border: none;
  
  @media (max-width: ${BREAKPOINTS.tablet}) {
    height: 300px;
  }
  
  @media (max-width: ${BREAKPOINTS.mobile}) {
    height: 250px;
  }
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
`;

const AnimatedTypeSample: React.FC<AnimatedTypeSampleProps> = ({ 
  className,
  maxWidth
}) => {
  return (
    <Container className={className} maxWidth={maxWidth}>
      <StyledIframe 
        src="/platoshat/animation.html" 
        title="Plato's Hat Animation"
        frameBorder="0"
        scrolling="no"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Container>
  );
};

export default AnimatedTypeSample; 