import React from 'react';
import styled from 'styled-components';
import { LAYOUT, BREAKPOINTS } from '../../styles/layout';

interface ProjectCardProps {
  title: string;
  description: string;
  role: string;
  timeframe: string;
  imageSrc: string;
  imageAlt: string;
  customImage?: React.ReactNode;
  readMoreLink?: string;
  appStoreLink?: string;
  isRounded?: boolean;
  link?: string;
  linkText?: string;
}

const ProjectSection = styled.section`
  margin: 100px auto;
  padding: ${LAYOUT.containerPadding};
  width: 100%;
  max-width: ${LAYOUT.maxWidth};
  
  &:last-child {
    margin-bottom: 100px;
  }
  
  @media (max-width: ${BREAKPOINTS.tablet}) {
    margin: 70px auto;
    padding: ${LAYOUT.containerPadding};
    
    &:last-child {
      margin-bottom: 70px;
    }
  }
`;

const ProjectContent = styled.article`
  margin-bottom: 2rem;
  width: 100%;
  
  @media (max-width: ${BREAKPOINTS.tablet}) {
    padding: 0;
  }
`;

const ProjectTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1.25rem;
  font-weight: 590;
  line-height: 1.3;
  color: var(--text);
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: var(--text);
  margin-bottom: 1.25rem;
  font-weight: 400;
  max-width: 95%;
  
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 0.9rem;
    line-height: 1.5;
  }
`;

const ProjectTextContainer = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
`;

const ProjectMeta = styled.div`
  margin-top: 25px;
  width: 100%;
  font-family: "SF Pro", -apple-system, Roboto, Helvetica, sans-serif;
  font-size: 21px;
  color: var(--text);
  margin-bottom: 25px;
  
  @media (max-width: 991px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const MetaInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 10px;
  
  @media (max-width: 991px) {
    max-width: 100%;
    padding-bottom: 8px;
  }
`;

const Role = styled.p`
  font-weight: 590;
  align-self: center;
  margin: 0;
  font-size: 21px;
  display: flex;
  align-items: center;
`;

const TimeframeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0;
  margin: 0;
  
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  }
`;

const Timeframe = styled.p`
  font-weight: 400;
  text-align: right;
  margin: 0;
  font-size: 21px;
  display: flex;
  align-items: center;
`;

const Divider = styled.hr`
  background-color: var(--divider);
  height: 1px;
  width: 100%;
  z-index: 10;
  flex-shrink: 0;
  margin: 0;
  border: none;
  
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ProjectImage = styled.img<{ isRounded?: boolean }>`
  width: 100%;
  height: auto;
  border-radius: ${props => props.isRounded ? '12px' : '0'};

  &[alt="Baby Journal Illustrations"] {
    [data-theme="dark"] & {
      filter: invert(1);
    }
  }
`;

const ReadMoreLink = styled.a`
  text-decoration: none;
  color: var(--secondary-text);
  display: inline-flex;
  align-items: center;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1.1rem;
  position: relative;
  transition: all 0.3s ease;
  width: fit-content;
  gap: 8px;
  
  svg {
    width: 20px;
    height: 20px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: center;
    animation: arrowPulse 2s infinite;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px; /* Fine-tune vertical alignment */
    
    path {
      stroke: currentColor;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: none;
    }
  }
  
  &:hover {
    color: var(--text);
    transform: translateX(4px);
    
    svg {
      transform: translateX(4px) scale(1.2);
      animation: none;
    }
  }

  @keyframes arrowPulse {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(2px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

const AppStoreContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  line-height: 1;
  transform: translateY(1px); /* Tiny adjustment for perfect alignment */
`;

const AppStoreBadge = styled.img`
  width: 85px;
  padding: 0;
  margin: 0;
  display: block;
  vertical-align: middle;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.05);
  }
`;

const ProductLink = styled(ReadMoreLink)``;

const ProjectCard: React.FC<ProjectCardProps> = ({
  title,
  description,
  role,
  timeframe,
  imageSrc,
  imageAlt,
  customImage,
  readMoreLink,
  appStoreLink,
  isRounded = false,
  link,
  linkText = "See the product"
}) => {
  // Determine if this is the Baby Journal project to use the text container
  const isBabyJournal = title === "Baby Journal";
  
  return (
    <ProjectSection className="project-section">
      <ProjectContent className="project-content">
        <ProjectTitle className="project-title">{title}</ProjectTitle>
        
        {isBabyJournal ? (
          <ProjectTextContainer className="project-text-container">
            <ProjectDescription className="project-description">{description}</ProjectDescription>
            <div>
              {readMoreLink && (
                <ReadMoreLink href={readMoreLink} className="read-more-link">
                  Read More
                  <svg viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"/>
                  </svg>
                </ReadMoreLink>
              )}
              {link && (
                <ReadMoreLink 
                  href={link} 
                  className="read-more-link" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  style={{ display: 'block', marginTop: '12px' }}
                >
                  {linkText}
                  <svg viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"/>
                  </svg>
                </ReadMoreLink>
              )}
            </div>
          </ProjectTextContainer>
        ) : (
          <>
            <ProjectDescription className="project-description">{description}</ProjectDescription>
            <div>
              {readMoreLink && (
                <ReadMoreLink href={readMoreLink} className="read-more-link">
                  Read More
                  <svg viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"/>
                  </svg>
                </ReadMoreLink>
              )}
              {link && (
                <ReadMoreLink 
                  href={link} 
                  className="read-more-link" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  style={{ marginLeft: readMoreLink ? '20px' : '0' }}
                >
                  {linkText}
                  <svg viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"/>
                  </svg>
                </ReadMoreLink>
              )}
            </div>
          </>
        )}
      </ProjectContent>
      <ProjectMeta className="project-meta">
        <MetaInfo className="meta-info">
          <Role className="role">{role}</Role>
          <TimeframeContainer>
            <Timeframe className="timeframe">{timeframe}</Timeframe>
            {appStoreLink && (
              <AppStoreContainer className="app-store-container">
                <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
                  <AppStoreBadge 
                    className="app-store-badge"
                    src="/assets/images/download on the app store.avif" 
                    alt={`Download ${title} on the App Store`} 
                  />
                </a>
              </AppStoreContainer>
            )}
          </TimeframeContainer>
        </MetaInfo>
        <Divider className="divider" />
      </ProjectMeta>
      {customImage ? (
        customImage
      ) : (
        link ? (
          <a href={link} target="_blank" rel="noopener noreferrer">
            <ProjectImage src={imageSrc} alt={imageAlt} isRounded={isRounded} />
          </a>
        ) : (
          <ProjectImage src={imageSrc} alt={imageAlt} isRounded={isRounded} />
        )
      )}
    </ProjectSection>
  );
};

export default ProjectCard; 