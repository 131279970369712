import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LAYOUT, BREAKPOINTS } from '../../styles/layout';

const HeaderContainer = styled.header`
  width: 100%;
  max-width: ${LAYOUT.maxWidth};
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 25px 20px;
  position: sticky;
  top: 0;
  background: var(--background);
  z-index: 1000;
  
  @media (max-width: ${BREAKPOINTS.tablet}) {
    padding: 20px;
  }
`;

const BackLink = styled(Link)`
  color: var(--text);
  text-decoration: none;
  font-size: 21px;
  font-weight: 590;
  transition: color 0.3s ease;
  padding: 5px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  
  &:hover {
    opacity: 0.8;
  }
  
  svg {
    width: 21px;
    height: 21px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: center;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    
    path {
      stroke: currentColor;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: none;
    }
  }
  
  &:hover svg {
    transform: translateX(-4px);
  }
  
  @media (max-width: ${BREAKPOINTS.tablet}) {
    font-size: 18px;
    
    svg {
      width: 18px;
      height: 18px;
      margin-top: -1px;
    }
  }
`;

const DesignerName = styled.div`
  font-weight: 590;
  font-size: 21px;
  color: var(--text);
  text-align: right;
  padding: 5px 0;
  
  @media (max-width: ${BREAKPOINTS.tablet}) {
    font-size: 18px;
  }
`;

interface HeaderProps {
  backLink?: string;
  backText?: string;
}

const Header: React.FC<HeaderProps> = ({ backLink = "/", backText = "Back" }) => {
  return (
    <HeaderContainer>
      {backLink && (
        <BackLink to={backLink}>
          <svg viewBox="0 0 24 24">
            <path d="M19 12H5M12 19l-7-7 7-7"/>
          </svg>
          {backText || 'Back'}
        </BackLink>
      )}
      <DesignerName>Nurullah Gökdogan</DesignerName>
    </HeaderContainer>
  );
};

export default Header; 